import React, { useRef,useState,forwardRef,useImperativeHandle, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react'
import axios from 'axios';
import $ from 'jquery'
import Modal from "react-bootstrap/Modal";
import {VFSBrowser} from './admin/components/VFSBrowser'
import { set } from 'lodash';



const RicheEditor = forwardRef((props, ref) => {
  
  const [isOpen, setIsOpen] = React.useState(false);
  const [seoRequest,setSeoRequest] = useState(null)
  
  const editorRef = useRef();
  useImperativeHandle(ref, () => ({
      log() {
        if (editorRef.current) {
          // console.log(editorRef.current.getContent());
          return editorRef.current.getContent()
        }
      },
      set(content){
        if (editorRef.current) {
          editorRef.current.setContent(content)
        }else{
          console.log(':(')

        }
      }
  }));

    // setTimeout(()=>{
    //   if(editorRef.current){
    //     alert(editorRef.current.setContent('<p>hello world!</p>'))
    //   }
    // },3000)

  const log = ()=>{
      if (editorRef.current) {
        console.log(editorRef.current.getContent());
      }
  }
    // setSeoRequest({
    //     workIdentifier:258,
    //     englishTitle:"test 1",
    //     englishDescription:editorRef.current.getContent(),
    //     frenchTitle:"test 1",
    //     frenchDescription:editorRef.current.getContent(),
    //     arabicTitle:"test 1",
    //     arabicDescription:editorRef.current.getContent()
    // })
    // console.log(seoRequest);
    // console.log(JSON.stringify(seoRequest))
    // };

  const handleEditorChange = (e) => {
    // console.log(
    //   'Content was updated:',
    //   e.target.getContent()
    // );
    console.log('edd');
    if(props.onchange){
      props.onchange()
    }
  }

  const handleResponseError = (error) => {
    console.log(error)
  }

  const showModal = () => { 
    setIsOpen(true);
  };

  const hideModal = () => {
      setIsOpen(false);
  };

  const insertImg = (url) =>{
    editorRef.current.insertContent('<img alt="Smiley face" width="400" src="' + url + '"/>');
  }

  // insertImg('https://images.pexels.com/photos/160107/pexels-photo-160107.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260');

  return (
    <>
      {/* <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue="<p>Wow ! ... It Works !!!</p>"
        apiKey="d1oewzaign1ffdgbsqmof00uk4e5bxpvn6bjzinldd5mr6zd"
        init={{
          height: 500,
          menubar: 'insert',
          plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount',
            'image'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | image \
              alignleft aligncenter alignright | \
              bullist numlist outdent indent | help'
        }}
        onChange={handleEditorChange}
      />
      <button onClick={log}>Log editor content</button> */}



      <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        initialValue="<p>Wow ! ... It Works !!!</p>"
        apiKey="d1oewzaign1ffdgbsqmof00uk4e5bxpvn6bjzinldd5mr6zd"
        init={{
          height: 500,
          menubar: 'insert',
          plugins: [
            "advlist autolink lists link image charmap print preview hr anchor pagebreak",
            "searchreplace wordcount visualblocks visualchars code fullscreen",
            "insertdatetime media nonbreaking save table contextmenu directionality",
            "emoticons template paste textcolor colorpicker textpattern"
          ],
          paste_as_text: true,
          mobile: {
            toolbar_drawer: "floating"
          },
          toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
          toolbar2: "print preview media | forecolor backcolor emoticons code",
          image_advtab: true,
          image_title: true,
          automatic_uploads: true,
          file_picker_types: 'image',
          file_picker_callback: function (cb, value, meta) {
            editorRef.current.windowManager.close(window);
            showModal();
            // var input = document.createElement('input');
            // input.setAttribute('type', 'file');
            // input.setAttribute('accept', 'image/*');

            /*
              Note: In modern browsers input[type="file"] is functional without
              even adding it to the DOM, but that might not be the case in some older
              or quirky browsers like IE, so you might want to add it to the DOM
              just in case, and visually hide it. And do not forget do remove it
              once you do not need it anymore.
            */

            // input.onchange = function () {
            //   var file = this.files[0];
            //   var max_size = 1000
            //   var reader = new FileReader();
            //   reader.onload = function () {
            //     /*
            //       Note: Now we need to register the blob in TinyMCEs image blob
            //       registry. In the next release this part hopefully won't be
            //       necessary, as we are looking to handle it internally.
            //     */
            //     var id = 'blobid' + (new Date()).getTime();
            //     var blobCache = editorRef.current.editorUpload.blobCache;
            //     var base64 = reader.result.split(',')[1];
            //     var blobInfo = blobCache.create(id, file, base64);
            //     var image_size = blobInfo.blob().size / 1000;
            //     if (image_size > max_size) {
            //       editorRef.current.windowManager.close(window);
            //       editorRef.current.notificationManager.open({
            //         text: 'Image is too large( ' + image_size + ') ,Maximum image size is:' + max_size + ' kB',
            //         type: 'error'
            //       });
            //       return;
            //     } else {
            //       blobCache.add(blobInfo);
            //       /* call the callback and populate the Title field with the file name */
            //       cb(blobInfo.blobUri(), { title: file.name });
            //     }

            //   };
            //   reader.readAsDataURL(file);
            // };

            // input.click();

          },
          // images_upload_url: 'postAcceptor.php',
          images_upload_handler: function (blobInfo, success, failure) {






            // success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
            //failure('Image is too large( '+ 800  + ') ,Maximum image size is:' + 800 + ' kB');

          }

        }}

        onChange={handleEditorChange}
      />
      {/* <button onClick={log}>Log editor content</button> */}
      <br />

      <Modal
        show={isOpen}
        onHide={hideModal}
        //size="lg"
        dialogClassName="modal-90w"
        centered
        fullscreen={true}
      >
        <Modal.Header>
          <Modal.Title>FileManager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VFSBrowser open={insertImg} close={hideModal}></VFSBrowser>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-danger" onClick={hideModal}>Cancel</button>
        </Modal.Footer>
      </Modal>

      {/* <button className="btn btn-success" onClick={showModal}>open modal</button> */}
    </>

  )
});

export default RicheEditor


    // <Editor
    //   initialValue="<p>Wow ! ... It Works !!!</p>"
    //   apiKey="d1oewzaign1ffdgbsqmof00uk4e5bxpvn6bjzinldd5mr6zd"
    //   init={{
    //     height: 300,
    //     menubar: false,
    //     paste_data_images: true,
    //     plugins: [
    //       "advlist autolink lists link image charmap print preview hr anchor pagebreak",
    //       "searchreplace wordcount visualblocks visualchars code fullscreen",
    //       "insertdatetime media nonbreaking save table contextmenu directionality",
    //       "emoticons template paste textcolor colorpicker textpattern"
    //     ],
    //     paste_as_text: true,
    //     mobile: {
    //       toolbar_drawer: "floating"
    //     },
    //     toolbar1: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
    //     toolbar2: "print preview media | forecolor backcolor emoticons",
    //     image_advtab: true,
		//     file_picker_callback: function(callback, value, meta) {
    //       if (meta.filetype == 'image') {
    //         //console.log('image')
    //         $('#upload').trigger('click');
    //         $('#upload').on('change', function() {
    //           var file = this.files[0];
    //           var reader = new FileReader();
    //           reader.onload = function(e) {
    //             callback(e.target.result, {
    //               alt: ''
    //             });
    //           };
    //           reader.readAsDataURL(file);
    //         });
    //       }
    //     },
    //   }}

    //   onEditorChange={handleEditorChange}
    // />



    // file_picker_callback: function (callback, value, meta) {
    //   if (meta.filetype == 'image') {
    //     //console.log('image')
    //     $('#upload').trigger('click');
    //     $('#upload').on('change', function () {
    //       var file = this.files[0];
    //       var reader = new FileReader();
    //       reader.onload = function (e) {
    //         callback(e.target.result, {
    //           alt: ''
    //         });
    //       };
    //       reader.readAsDataURL(file);
    //     });
    //   }

    // }