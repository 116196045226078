import React, { useEffect, useState } from 'react'
import { PUBLIC_URL } from './../../../Varialbles'
import { Link, useHistory } from 'react-router-dom'
import RicheEditor from './../../RicheEditor'
import { useFormik } from 'formik';
import Modal from "react-bootstrap/Modal";
import { VFSBrowser } from './../../admin/components/VFSBrowser'
import {useSelector, useDispatch} from 'react-redux'
import {addWebDev} from './../../../services/work.service'
import { loadAdminDetailedWorks } from '../../../redux/Actions/WorkActions';



function Add_Dev() {

    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [imgFocus, setImgFocus] = useState(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [tabValidation,setTabValidation] = useState({
        english:true,
        french:true,
        arabic:true
    });
    const adminWork = useSelector(state => state.WorkReducer.adminWork);
    const adminDetailedWorks = useSelector(state => state.WorkReducer.adminDetailedWorks);
    console.log(adminWork)
    console.log(adminDetailedWorks)

    const [screenshots,setScreenshots] = useState({
        web:null,
        mobile:null
    })
    /**
     * FORM VALIDATION ///////////////////////////////////////////////////////////////////
     */

     const validate = (values, props /* only available when using withFormik */) => {

        const errors = {};
        let [french,english,arabic] = [true,true,true];

        if (!values.englishMobileTitle) {
          errors.englishMobileTitle = 'Required';
          english = false;
        } 
        if (!values.englishMobileDescription) {
          errors.englishMobileDescription = 'Required';
          english = false;
        } 
        if (!values.englishWebTitle) {
          errors.englishWebTitle = 'Required';
          english = false;
        } 
        if (!values.englishWebDescription) {
          errors.englishWebDescription = 'Required';
          english = false;
        } 
        if (!values.arabicMobileTitle) {
          errors.arabicMobileTitle = 'Required';
          arabic = false;
        } 
        if (!values.arabicMobileDescription) {
          errors.arabicMobileDescription = 'Required';
          arabic = false;
        } 
        if (!values.arabicWebTitle) {
          errors.arabicWebTitle = 'Required';
          arabic = false;
        } 
        if (!values.arabicWebDescription) {
          errors.arabicWebDescription = 'Required';
          arabic = false;
        } 
        if (!values.frenchMobileTitle) {
          errors.frenchMobileTitle = 'Required';
          french = false;
        } 
        if (!values.frenchMobileDescription) {
          errors.frenchMobileDescription = 'Required';
          french = false;
        } 
        if (!values.frenchWebTitle) {
          errors.frenchWebTitle = 'Required';
          french = false;
        } 
        if (!values.frenchWebDescription) {
          errors.frenchWebDescription = 'Required';
          french = false;
        } 
        // if (!values.siteUrl) {
        //   errors.siteUrl = 'Required';
        // } 
        if (!screenshots.web) {
          errors.webScreenshot = 'Required';
        } 
        if (!screenshots.mobile) {
          errors.mobileScreenshot = 'Required';
        } 
      
        setTabValidation({french:french,arabic:arabic,english:english})
        //...
        
      
        return errors;
    };

    const getInitialState = ()=>{
        let initialState = {}
        if(adminDetailedWorks && adminDetailedWorks.detailedWorks.data[0].webDev){
            adminDetailedWorks.detailedWorks.data.forEach((work)=>{
                switch(work.language){
                    case 'LANG_EN' : 
                        initialState = {...initialState,englishMobileTitle:work.webDev.mobileTitle,englishMobileDescription:work.webDev.mobileDescription,englishWebTitle:work.webDev.webTitle,englishWebDescription:work.webDev.webDescription}
                        break;
                    case 'LANG_FR' :
                        initialState = {...initialState,frenchMobileTitle:work.webDev.mobileTitle,frenchMobileDescription:work.webDev.mobileDescription,frenchWebTitle:work.webDev.webTitle,frenchWebDescription:work.webDev.webDescription}
                        break;
                    case 'LANG_AR' : 
                        initialState = {...initialState,arabicMobileTitle:work.webDev.mobileTitle,arabicMobileDescription:work.webDev.mobileDescription,arabicWebTitle:work.webDev.webTitle,arabicWebDescription:work.webDev.webDescription}
                        break
                }
            })
            initialState = {...initialState,siteUrl:adminDetailedWorks.detailedWorks.data[0].webDev.siteUrl}
            console.log('initiale state ',initialState);
            
            return initialState
        }else{
            return {
                siteUrl:'',
                englishMobileTitle: '',
                englishMobileDescription:'',
                englishWebTitle:'',
                englishWebDescription:'',
                arabicMobileTitle:'',
                arabicMobileDescription:'',
                arabicWebTitle:'',
                arabicWebDescription:'',
                frenchMobileTitle:'',
                frenchMobileDescription:'',
                frenchWebTitle:'',
                frenchWebDescription:''
            }
        }
    }


    const formik = useFormik({
        initialValues: getInitialState(),
        // validateOnChange:false,
        // validateOnBlur:false,
        validate,
        onSubmit: values => {
            // alert(JSON.stringify(values, null, 2));
            // formik.validateOnChange = true;
            let request = {
                workIdentifier: adminWork.response.data[0].workIdentifier,
                siteUrl: values.siteUrl,
                frenchWebTitle: values.frenchWebTitle,
                frenchMobileTitle: values.frenchMobileTitle,
                frenchWebDescription: values.frenchWebDescription,
                frenchMobileDescription:values.frenchMobileDescription,
                englishWebTitle: values.englishWebTitle,
                englishMobileTitle: values.englishMobileTitle,
                englishWebDescription: values.englishWebDescription,
                englishMobileDescription: values.englishMobileDescription,
                arabicWebTitle: values.arabicWebTitle,
                arabicMobileTitle: values.arabicMobileTitle,
                arabicWebDescription: values.arabicWebDescription,
                arabicMobileDescription: values.arabicMobileDescription,
                webScreenshot: screenshots.web,
                mobileScreenshot:screenshots.mobile
            }

            addWebDev(JSON.stringify(request)).then(
                (response) => {
                    dispatch(loadAdminDetailedWorks(adminWork.response.data[0].workIdentifier))
                    .then(() => {
                        history.push('/admin/dashboard/works/add_work')
                    })
                    .catch(() => {
                    });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
    
                    console.log(_content)
                }
                );


        },
    });

    useEffect(()=>{
        if(adminDetailedWorks && adminDetailedWorks.detailedWorks.data[0].webDev ){
            setScreenshots({web:adminDetailedWorks.detailedWorks.data[0].webDev.images.webScreenshot,mobile:adminDetailedWorks.detailedWorks.data[0].webDev.images.mobileScreenshot})
        }
    },[adminDetailedWorks])

    
    //////////////////////////////////////////////////////////////////////////////////////

    /**
     * FILE MANAGER MODAL FUNCTIONS
     */

     const showModal = (img) => {
        setImgFocus(img)
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const insertImg = (url) => {
        switch (imgFocus) {
            case 1:
                setScreenshots({...screenshots,web:url})
                break;
            case 2:
                setScreenshots({...screenshots,mobile:url})
                break;
        }
        
    }

    //////////////////////////////////////////////////////////////////////////////////////


    // console.log("public url :" + process.env.PUBLIC_URL)
    const [tabs, setTabs] = useState([
        { id: 1, display: "block" },
        { id: 2, display: "none" },
        { id: 3, display: "none" }
    ])
    const [colors, setColors] = useState([
        {
            id: 1,
            color: "#D6D6D6"
        },
        {
            id: 2,
            color: "#EAEAEA"
        },
        {
            id: 3,
            color: "#EAEAEA"
        }
    ])

    const showTab = (tab) => {
        switch (tab) {
            case 1:
                setTabs([{ id: 1, display: "block" }, { id: 2, display: "none" }, { id: 3, display: "none" }])
                setColors([{ id: 1, color: "#D6D6D6" }, { id: 2, color: "#EAEAEA" }, { id: 3, color: "#EAEAEA" }])
                break;
            case 2:
                setTabs([{ id: 1, display: "none" }, { id: 2, display: "block" }, { id: 3, display: "none" }])
                setColors([{ id: 1, color: "#EAEAEA" }, { id: 2, color: "#D6D6D6" }, { id: 3, color: "#EAEAEA" }])
                break;
            case 3:
                setTabs([{ id: 1, display: "none" }, { id: 2, display: "none" }, { id: 3, display: "block" }])
                setColors([{ id: 1, color: "#EAEAEA" }, { id: 2, color: "#EAEAEA" }, { id: 3, color: "#D6D6D6" }])
                break;
        }
    }


    return (
        <>
            <div className="admin-section">
                <div className="add-dev-admin">
                    <div className="container-header">
                        <h2>Add Devlopment web & mobile</h2>
                        <Link to="/admin/dashboard/works/add_work"><button className="button-add"><i className='bx bx-arrow-back'></i> Back</button></Link>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="url-dev">
                            <p>Website url</p>
                            <input  
                                id="siteUrl"
                                name="siteUrl"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.siteUrl}
                            />
                            {formik.errors.siteUrl ? <div className="error">{formik.errors.siteUrl}</div> : null}
                        </div>
                        <div className="upload-add-dev-all">
                            <h5>Screenshot :</h5>
                            <div className="upload-add-dev">
                                <div className="web">
                                    <p>web</p>
                                    <button type="button" onClick={() => showModal(1)}><i className='bx bxs-cloud-upload'></i> Upload</button><br />
                                    {screenshots.web && <img src={screenshots.web} className="work-uploaded-img" alt="Web screenshot"></img>}
                                    {(!screenshots.web || screenshots.web == "")? <div className="error">{formik.errors.webScreenshot}</div> : null}
                                </div>
                                <div className="mobile">
                                    <p>mobile</p>
                                    <button type="button" onClick={() => showModal(2)}><i className='bx bxs-cloud-upload'></i> Upload</button><br />
                                    {screenshots.mobile && <img src={screenshots.mobile} className="work-uploaded-img" alt="Mobile screenshot"></img>}
                                    {(!screenshots.mobile || screenshots.mobile == "") ? <div className="error">{formik.errors.mobileScreenshot}</div> : null}
                                </div>
                            </div>
                        </div>


                        <div className="tab-lang">
                            <div className="w3-bar">
                                <button className="w3-button" type="button" style={{ background: `${colors[0].color}` }} onClick={() => showTab(1)}>English {!tabValidation.english && <i className='bx bxs-error-circle' style={{color:'#ff0909'}}  ></i>}</button>
                                <button className="w3-button border-frensh" type="button" style={{ background: `${colors[1].color}` }} onClick={() => showTab(2)}>Frensh {!tabValidation.french && <i className='bx bxs-error-circle' style={{color:'#ff0909'}}  ></i>}</button>
                                <button className="w3-button" type="button" style={{ background: `${colors[2].color}` }} onClick={() => showTab(3)}>Arabic  {!tabValidation.arabic && <i className='bx bxs-error-circle' style={{color:'#ff0909'}}  ></i>}</button>
                            </div>
                            <div className="tab-container">
                                <div id="English" style={{ display: `${tabs[0].display}` }} >
                                    <div className="add-dev-info">
                                        <div className="title-input">
                                            <p>Subtitle Web</p>
                                            <input 
                                                id="englishWebTitle"
                                                name="englishWebTitle"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.englishWebTitle}
                                            />
                                            {formik.errors.englishWebTitle ? <div className="error">{formik.errors.englishWebTitle}</div> : null}
                                        </div>
                                        <div className="title-input">
                                            <p>Description Web</p>
                                            <textarea 
                                                id="englishWebDescription"
                                                name="englishWebDescription"
                                                type="textarea"
                                                onChange={formik.handleChange}
                                                value={formik.values.englishWebDescription}
                                            />
                                            {formik.errors.englishWebDescription ? <div className="error">{formik.errors.englishWebDescription}</div> : null}
                                        </div>
                                        <div className="title-input">
                                            <p>Subtitle Mobile</p>
                                            <input 
                                                id="englishMobileTitle"
                                                name="englishMobileTitle"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.englishMobileTitle}
                                            />
                                            {formik.errors.englishMobileTitle ? <div className="error">{formik.errors.englishMobileTitle}</div> : null}
                                        </div>
                                        <div className="title-input">
                                            <p>Description Mobile</p>
                                            <textarea 
                                                id="englishMobileDescription"
                                                name="englishMobileDescription"
                                                type="textarea"
                                                onChange={formik.handleChange}
                                                value={formik.values.englishMobileDescription}
                                            />
                                            {formik.errors.englishMobileDescription ? <div className="error">{formik.errors.englishMobileDescription}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div id="Frensh" style={{ display: `${tabs[1].display}` }}>
                                    <div className="add-dev-info">
                                        <div className="title-input">
                                            <p>Subtitle Web</p>
                                            <input 
                                                id="frenchWebTitle"
                                                name="frenchWebTitle"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.frenchWebTitle}
                                            />
                                            {formik.errors.frenchWebTitle ? <div className="error">{formik.errors.frenchWebTitle}</div> : null}
                                        </div>
                                        <div className="title-input">
                                            <p>Description Web</p>
                                            <textarea 
                                                id="frenchWebDescription"
                                                name="frenchWebDescription"
                                                type="textarea"
                                                onChange={formik.handleChange}
                                                value={formik.values.frenchWebDescription}
                                            />
                                            {formik.errors.frenchWebDescription ? <div className="error">{formik.errors.frenchWebDescription}</div> : null}
                                        </div>
                                        <div className="title-input">
                                            <p>Subtitle Mobile</p>
                                            <input
                                                id="frenchMobileTitle"
                                                name="frenchMobileTitle"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.frenchMobileTitle}
                                            />
                                            {formik.errors.frenchMobileTitle ? <div className="error">{formik.errors.frenchMobileTitle}</div> : null}
                                        </div>
                                        <div className="title-input">
                                            <p>Description Mobile</p>
                                            <textarea  
                                                id="frenchMobileDescription"
                                                name="frenchMobileDescription"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.frenchMobileDescription} 
                                            />
                                            {formik.errors.frenchMobileDescription ? <div className="error">{formik.errors.frenchMobileDescription}</div> : null}
                                        </div>
                                    </div>
                                </div>

                                <div id="Arabic" style={{ display: `${tabs[2].display}` }}>
                                    <div className="add-dev-info">
                                        <div className="title-input">
                                            <p>Subtitle Web</p>
                                            <input
                                                id="arabicWebTitle"
                                                name="arabicWebTitle"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.arabicWebTitle}
                                             />
                                             {formik.errors.arabicWebTitle ? <div className="error">{formik.errors.arabicWebTitle}</div> : null}
                                        </div>
                                        <div className="title-input">
                                            <p>Description Web</p>
                                            <textarea 
                                                id="arabicWebDescription"
                                                name="arabicWebDescription"
                                                type="textarea"
                                                onChange={formik.handleChange}
                                                value={formik.values.arabicWebDescription}
                                            />
                                            {formik.errors.arabicWebDescription ? <div className="error">{formik.errors.arabicWebDescription}</div> : null}
                                        </div>
                                        <div className="title-input">
                                            <p>Subtitle Mobile</p>
                                            <input 
                                                id="arabicMobileTitle"
                                                name="arabicMobileTitle"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.arabicMobileTitle}
                                            />
                                            {formik.errors.arabicMobileTitle ? <div className="error">{formik.errors.arabicMobileTitle}</div> : null}
                                        </div>
                                        <div className="title-input">
                                            <p>Description Mobile</p>
                                            <textarea 
                                                id="arabicMobileDescription"
                                                name="arabicMobileDescription"
                                                type="textarea"
                                                onChange={formik.handleChange}
                                                value={formik.values.arabicMobileDescription}
                                            />
                                            {formik.errors.arabicMobileDescription ? <div className="error">{formik.errors.arabicMobileDescription}</div> : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="alignement-button-fin-page">
                            <Link to="/admin/dashboard/works"><button className="button-fin-page"><i className='bx bxs-x-circle'></i> Cancel</button></Link>
                            {/* <Link to="/admin/dashboard/works"></Link> */}
                            <button className="button-fin-page" type="submite"><i className='bx bxs-save'></i> Save </button>
                        </div>
                    </form>
                </div>
            </div>
            <Modal
                show={isOpen}
                onHide={hideModal}
                //size="lg"
                dialogClassName="modal-90w"
                centered
                fullscreen={true}
            >
                <Modal.Header>
                    <Modal.Title>FileManager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <VFSBrowser open={insertImg} close={hideModal}></VFSBrowser>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={hideModal}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Add_Dev
