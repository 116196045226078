import React from 'react'
import { useSelector } from "react-redux";
import {Route,Redirect} from 'react-router-dom'
function ProtectedRout(props) {
    const { user: currentUser } = useSelector((state) => state.auth);
 


    return currentUser ? (
    <Route {...props} />
     ) : (
    <Redirect
      to={{
        pathname: "/signin"
      }}
    />
  );
};

export default ProtectedRout
