import React from 'react'
import Menu from './../Menu';
import LanguageSwitcher from './../../components/LanguageSwitcher'
import PageNotFound from './PageNotFound';
import PageNotFoundHeader from './pageNotFoundHeader';
import Footer from './../Footer';
import Music from './../Music';


function Complete404page() {
    return (
        <>
            <Menu></Menu>
            <LanguageSwitcher></LanguageSwitcher>
            <PageNotFoundHeader/>
            <main>
                <PageNotFound/>
                <Footer></Footer>
            </main>
            <Music></Music>

        </>
    )
}

export default Complete404page
