import axios from 'axios'
import {API_URL} from './../Varialbles'

const api_auth_url = API_URL + '/auth/'

const signup = (username,password) => {
    return axios.post(api_auth_url + 'signup',{username,password});
}

const signin = (username,password) => {
    return axios.post(api_auth_url + 'signin',{username,password}).then((response)=>{
        if(response.data.token){
            localStorage.setItem("user",JSON.stringify(response.data));
        }
        return response.data
    });
}

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("state");
}

export default {signup,logout,signin}