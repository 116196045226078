const initaialState = {
    playing: false
}


export const Reducer = (state = initaialState, action) => {
    switch (action.type) {
        case "SET_PLAYING" : {
            return {...state, playing: action.payload}
        }
        case "TOGGLE" : {
            return {...state, playing: !state.playing}
        }
        default :
            return state;
    }
}