/**
 * @author Timur Kuzhagaliyev <tim.kuzh@gmail.com>
 * @copyright 2020
 * @license MIT
 */

 import {
    ChonkyActions,
    ChonkyFileActionData,
    FileArray,
    FileBrowserProps,
    FileData,
    FileHelper,
    FullFileBrowser,setChonkyDefaults
} from 'chonky';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { showActionNotification } from './util/util';
import { storage, db } from './../../../firebase';
import uniqid from 'uniqid';
import LondingSpinner from './../../LondingSpinner'
import { ChonkyIconFA } from 'chonky-icon-fontawesome';





// We define a custom interface for file data because we want to add some custom fields
// to Chonky's built-in `FileData` interface.
interface CustomFileData extends FileData {
    parentId?: string;
    childrenIds?: string[];
}
interface CustomFileMap {
    [fileId: string]: CustomFileData;
}



export type VFSProps = Partial<FileBrowserProps>;

export const VFSBrowser: React.FC<VFSProps> = React.memo((props:any) => {

    const [FileMap,SetFileMap] = useState<any>(null);
    setChonkyDefaults({ iconComponent: ChonkyIconFA });
    //const [imageFile,setImageFile] = useState<File>();
    let imageFile: any = null;
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [isLoading,setLoading] = useState(false);
    const [imgUrl, setImgUrl] = useState(null);






    // Hook that sets up our file map and defines functions used to mutate - `deleteFiles`,
    // `moveFiles`, and so on.
    const useCustomFileMap = () => {
        // const { baseFileMap, rootFolderId } = useMemo(prepareCustomFileMap, []);
        const baseFileMap = FileMap;
        const rootFolderId = "qwerty123456";
        const [fileMap, setFileMap] = useState(baseFileMap);
        const [currentFolderId, setCurrentFolderId] = useState(rootFolderId);

        useEffect(()=>{
            db.collection("filemap").doc("bD4cPb4Zua9sxE03kXy3")
            .get()
            .then(function(doc) {
                if (doc.exists) {
                    setFileMap(doc.data() as CustomFileData)
                } else {
                    console.log("No such document!");
                }
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
    },[])
    // Setup the React state for our file map and the current folder.
    



    // Setup the function used to reset our file map to its initial value. Note that
    // here and below we will always use `useCallback` hook for our functions - this is
    // a crucial React performance optimization, read more about it here:
    // https://reactjs.org/docs/hooks-reference.html#usecallback
    const resetFileMap = useCallback(() => {
        setFileMap(baseFileMap);
        setCurrentFolderId(rootFolderId);
    }, [baseFileMap, rootFolderId]);

    // Setup logic to listen to changes in current folder ID without having to update
    // `useCallback` hooks. Read more about it here:
    // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
    const currentFolderIdRef = useRef(currentFolderId);
    useEffect(() => {
        currentFolderIdRef.current = currentFolderId;
    }, [currentFolderId]);

    const deleteFile = (pathToFile: string | undefined, fileName: string) => {
        const ref = storage.ref(pathToFile);
        const childRef = ref.child(fileName);
        childRef.delete().then(()=>console.log('file deleted'))
    }

    const deleteFolderContent = (folderId : string,fileMap : CustomFileMap) =>{
        
            fileMap[folderId].childrenIds?.forEach((id)=>{
                if( !fileMap[id].isDir){
                    deleteFile("rootFolder",fileMap[id].name);
                }else {
                    deleteFolderContent(id,fileMap);
                }
            })
        
    }


    // Function that will be called when user deletes files either using the toolbar
    // button or `Delete` key.
    const deleteFiles = useCallback((files: CustomFileData[]) => {
        // We use the so-called "functional update" to set the new file map. This
        // lets us access the current file map value without having to track it
        // explicitly. Read more about it here:
        // https://reactjs.org/docs/hooks-reference.html#functional-updates
        if(window.confirm("Are u sur u want to delete this file/folder ?")){
            setFileMap((currentFileMap: any) => {
                // Create a copy of the file map to make sure we don't mutate it.
                const newFileMap = { ...currentFileMap };
    
                files.forEach((file) => {

                    if(!file.isDir){
                        //console.log('trying to delete file : ',getFolderPath({...currentFileMap},currentFolderIdRef.current,rootFolderId));
                        deleteFile("rootFolder",file.name); 
                    }else{
                        console.log(file.id)
                        console.log('else')
                        deleteFolderContent(file.id,{...currentFileMap})
                    }

                    // Delete file from the file map.
                    delete newFileMap[file.id];
        
                    // Update the parent folder to make sure it doesn't try to load the
                    // file we just deleted.
                    if (file.parentId) {
                        const parent = newFileMap[file.parentId]!;
                        const newChildrenIds = parent.childrenIds!.filter(
                            (id: string) => id !== file.id
                        );
                        newFileMap[file.parentId] = {
                            ...parent,
                            childrenIds: newChildrenIds,
                            childrenCount: newChildrenIds.length,
                        };
                    }
                       
                });
                const collectionRef = db.collection('filemap');
                setLoading(true)
                collectionRef.doc("bD4cPb4Zua9sxE03kXy3").update({ ...newFileMap }).then(() => {
                    setLoading(false);
                }).catch(()=>{
                    alert('something went wrong')
                    setLoading(false)
                })
                return newFileMap;
            });
        }
                
        
    }, []);

    // Function that will be called when files are moved from one folder to another
    // using drag & drop.
    const moveFiles = useCallback(
        (
            files: CustomFileData[],
            source: CustomFileData,
            destination: CustomFileData
        ) => {
            setFileMap((currentFileMap: any) => {
                const newFileMap = { ...currentFileMap };
                const moveFileIds = new Set(files.map((f) => f.id));

                // Delete files from their source folder.
                const newSourceChildrenIds = source.childrenIds!.filter(
                    (id) => !moveFileIds.has(id)
                );
                newFileMap[source.id] = {
                    ...source,
                    childrenIds: newSourceChildrenIds,
                    childrenCount: newSourceChildrenIds.length,
                };

                // Add the files to their destination folder.
                const newDestinationChildrenIds = [
                    ...destination.childrenIds!,
                    ...files.map((f) => f.id),
                ];
                newFileMap[destination.id] = {
                    ...destination,
                    childrenIds: newDestinationChildrenIds,
                    childrenCount: newDestinationChildrenIds.length,
                };

                // Finally, update the parent folder ID on the files from source folder
                // ID to the destination folder ID.
                files.forEach((file) => {
                    newFileMap[file.id] = {
                        ...file,
                        parentId: destination.id,
                    };
                });
                const collectionRef = db.collection('filemap');
                collectionRef.doc("bD4cPb4Zua9sxE03kXy3").update({ ...newFileMap }).then(() => { })
                return newFileMap;
            });
        },
        []
    );

    const uploadFile = () => {
        inputFileRef.current?.click();
    }

    const getFolderPath = (fileMap:CustomFileMap,currentFolderId:string,rootFolderId:string)=>{
        let  folders = [];
        let cfi = currentFolderId;
        console.log('cfi : ',cfi)
        let rfi = rootFolderId;
        console.log('rfi : ',rfi)
        while(cfi !== rfi){
            folders.push(fileMap[cfi].name);
            cfi = fileMap[cfi].parentId!;
        }
        console.log(folders)
        const reversed = folders.reverse();
        let path = "rootFolder/"
        for(let i = 0;i<reversed.length;i++){
            path=path+reversed[i]+'/';
        }
        return path;
    }

    // Function that will be called when user creates a new folder using the toolbar
    // button. That that we use incremental integer IDs for new folder, but this is
    // not a good practice in production! Instead, you should use something like UUIDs
    // or MD5 hashes for file paths.
    const idCounter = useRef(0);
    const createFolder = useCallback((folderName: string) => {
        setFileMap((currentFileMap: any) => {
            const newFileMap = { ...currentFileMap };

            // Create the new folder
            const newFolderId = `${uniqid()}`;
            newFileMap[newFolderId] = {
                id: newFolderId,
                name: folderName,
                isDir: true,
                modDate: new Date(),
                parentId: currentFolderIdRef.current,
                childrenIds: [],
                childrenCount: 0,
            };

            // Update parent folder to reference the new folder.
            const parent = newFileMap[currentFolderIdRef.current];
            newFileMap[currentFolderIdRef.current] = {
                ...parent,
                childrenIds: [...parent.childrenIds!, newFolderId],
                childrenCount: parent.childrenCount + 1
            };

            // console.log(getFolderPath({ ...currentFileMap },currentFolderIdRef.current,rootFolderId));
            // var file = new File(["temp"], "temp.txt", {
            //     type: "text/plain",
            // });
            
            //const fileRef = storage.ref().child(`roorFolder/${folderName}/temp.txt`).put(file).then(()=>console.log('folder is created')).catch((err)=>console.log(err));
            const collectionRef = db.collection('filemap');
            setLoading(true)
            var updateTas = collectionRef.doc("bD4cPb4Zua9sxE03kXy3").update({ ...newFileMap }).then(() => {  
                setLoading(false)  
            }).catch(()=>{
                alert('something went wrong')
                setLoading(false)
            })
            
            return newFileMap;

        });
    }, []);

    return {
        fileMap,
        setFileMap,
        currentFolderId,
        setCurrentFolderId,
        resetFileMap,
        deleteFiles,
        moveFiles,
        createFolder,
        uploadFile,
        getFolderPath
    };
};

const useFiles = (
    fileMap: CustomFileMap,
    currentFolderId: string
): FileArray => {
    return useMemo(() => {
        if(fileMap){
            const currentFolder = fileMap[currentFolderId];
            const childrenIds = currentFolder.childrenIds!;
            const files = childrenIds.map((fileId: string) => fileMap[fileId]);
            return files;
        }else{
            return [null]
        }
    }, [currentFolderId, fileMap]);
};



const useFolderChain = (
    fileMap: CustomFileMap,
    currentFolderId: string
): FileArray => {
    return useMemo(() => {
        if(fileMap){
            const currentFolder = fileMap[currentFolderId];

        const folderChain = [currentFolder];

        let parentId = currentFolder.parentId;
        while (parentId) {
            const parentFile = fileMap[parentId];
            if (parentFile) {
                folderChain.unshift(parentFile);
                parentId = parentFile.parentId;
            } else {
                break;
            }
        }

        return folderChain;
        }else{
            return []
        }
    }, [currentFolderId, fileMap]);
};

const useFileActionHandler = (
    setCurrentFolderId: (folderId: string) => void,
    deleteFiles: (files: CustomFileData[]) => void,
    moveFiles: (files: FileData[], source: FileData, destination: FileData) => void,
    createFolder: (folderName: string) => void,
    uplaodFile:()=> void
) => {
    return useCallback(
        (data: ChonkyFileActionData) => {
            if (data.id === ChonkyActions.OpenFiles.id) {
                const { targetFile, files } = data.payload;
                const fileToOpen:any = targetFile ?? files[0];
                if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
                    setCurrentFolderId(fileToOpen.id);
                    return;
                }else{
                    if(props.open && fileToOpen.thumbnailUrl){
                        props.open(fileToOpen.thumbnailUrl);
                        props.close();
                    }
                }
            } else if (data.id === ChonkyActions.DeleteFiles.id) {
                deleteFiles(data.state.selectedFilesForAction!);
            } else if (data.id === ChonkyActions.MoveFiles.id) {
                moveFiles(
                    data.payload.files,
                    data.payload.source!,
                    data.payload.destination
                );
            } else if (data.id === ChonkyActions.CreateFolder.id) {
                const folderName = prompt('Provide the name for your new folder:');
                if (folderName) createFolder(folderName);
            }else if (data.id === ChonkyActions.UploadFiles.id) {
                uplaodFile();
            }

            showActionNotification(data);
        },
        [createFolder, deleteFiles, moveFiles, setCurrentFolderId,uplaodFile]
    );
};





    const {
        fileMap,
        setFileMap,
        currentFolderId,
        setCurrentFolderId,
        resetFileMap,
        deleteFiles,
        moveFiles,
        createFolder,
        uploadFile,
        getFolderPath
    } = useCustomFileMap();
    const files = useFiles(fileMap, currentFolderId);
    const folderChain = useFolderChain(fileMap, currentFolderId);
    const handleFileAction = useFileActionHandler(
        setCurrentFolderId,
        deleteFiles,
        moveFiles,
        createFolder,
        uploadFile
    );
    const fileActions = useMemo(
        () => [ChonkyActions.CreateFolder, ChonkyActions.DeleteFiles,ChonkyActions.UploadFiles],
        []
    );
    const thumbnailGenerator = useCallback(
        (file: FileData) =>
            file.thumbnailUrl ? `${file.thumbnailUrl}` : null,
        []
    );

    // const collectionRef = db.collection('filemap');
    // const BbaseFileMap = (DemoFsMap.fileMap as unknown) as CustomFileMap;
    // collectionRef.doc("bD4cPb4Zua9sxE03kXy3").update(BbaseFileMap).then(() => { })


        
    const handleFileInputClick = (e: React.FormEvent) => {
        const files = (e.target as HTMLInputElement).files
        if (files && files.length > 0) {
            imageFile = files[0]
        }
        if (imageFile) {
            setLoading(true)
            const fileRef = storage.ref().child(`rootFolder/${imageFile.name}`);
            const uploadTask = fileRef.put(imageFile).then(() => {
                fileRef.getDownloadURL().then((url: any) => {
                    setImgUrl(url);
                    setFileMap((currentFileMap: any) => {
                        const newFileMap = { ...currentFileMap };

                        // Create the new folder
                        const newFileId = `${uniqid()}`;
                        newFileMap[newFileId] = {
                            id: newFileId,
                            name: imageFile.name,
                            modDate: new Date(),
                            parentId: currentFolderId,
                            thumbnailUrl: url
                        };
                        // Update parent folder to reference the new folder.
                        const parent = newFileMap[currentFolderId];
                        newFileMap[currentFolderId] = {
                            ...parent,
                            childrenIds: [...parent.childrenIds!, newFileId],
                        };
                        const collectionRef = db.collection('filemap');
                        collectionRef.doc("bD4cPb4Zua9sxE03kXy3").update({ ...newFileMap }).then(() => {
                            setLoading(false)
                        }).catch(()=>{
                            alert('something went wrong')
                            setLoading(false)
                        })
                        return newFileMap;
                    });
                })
            });
        }}

        

        
    
        
        

    return (
        <>
            {/* <Button
                id="reset-btn"
                size="small"
                color="primary"
                variant="contained"
                onClick={resetFileMap}
                style={{ marginBottom: 15 }}
            >
                Reset file map
            </Button> */}
            <div style={{ height: 400,position: 'relative' }}>
                <FullFileBrowser
                    files={files}
                    folderChain={folderChain}
                    fileActions={fileActions}
                    onFileAction={handleFileAction}
                    thumbnailGenerator={thumbnailGenerator}
                    {...props}
                />
                {isLoading && <div className="loading-container">
                    <LondingSpinner></LondingSpinner>
                </div>}
                <input type="file" hidden={true} ref={inputFileRef} accept='image/*' onChange={handleFileInputClick} />
                <br />
                {/* <button className="btn btn-warning" onClick={()=>ddeleteFolder("rootFolder/pdfs")} >delete folder</button> */}
            </div>
            
        </>
    );
});