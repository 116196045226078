import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import setupInterceptors from "./services/setupInterceptors";
import Store from './redux/Store'



i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs : ['en','fr','ar'],
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    },
    detection : {
      order: ['cookie','htmlTag',  'localStorage', 'navigator', 'path', 'subdomain'],
      caches:['cookie'],
    },
    backend:{
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
    react:{ useSuspense : false }
    
});

const loading = (
  <div className="py-4 text-center">
    <h2>Loading...</h2>
  </div>
)

ReactDOM.render(
<Suspense fallback={loading}>
  <React.StrictMode>
      <App />
  </React.StrictMode>
</Suspense>,
document.getElementById('root')
);

setupInterceptors(Store);
reportWebVitals();
