import React, { useState, useRef, useEffect } from 'react'
import { PUBLIC_URL } from './../../../Varialbles'
import { Link, useHistory } from 'react-router-dom'
import RicheEditor from './../../RicheEditor'
import { Modal, Button } from 'react-bootstrap'
import { title } from 'process'
import { useFormik } from 'formik';
import {useSelector, useDispatch} from 'react-redux'
import {addSeo} from './../../../services/work.service'
import { loadAdminDetailedWorks } from '../../../redux/Actions/WorkActions';


function AddSeo() {

    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const adminWork = useSelector(state => state.WorkReducer.adminWork);
    const adminDetailedWorks = useSelector(state => state.WorkReducer.adminDetailedWorks);

    const [editorsValidation,setEditorsValidation] = useState({
        englishEdidor:true,
        frenchEditor:true,
        arabicEditor:true
    })

    const englishEditor = useRef();
    const frenchEditor = useRef();
    const arabicEditor = useRef();



    const [tabs, setTabs] = useState([
        { id: 1, display: "block" },
        { id: 2, display: "none" },
        { id: 3, display: "none" }
    ])
    const [colors, setColors] = useState([
        {
            id: 1,
            color: "#D6D6D6"
        },
        {
            id: 2,
            color: "#EAEAEA"
        },
        {
            id: 3,
            color: "#EAEAEA"
        }
    ])

    const showTab = (tab) => {
        switch (tab) {
            case 1:
                setTabs([{ id: 1, display: "block" }, { id: 2, display: "none" }, { id: 3, display: "none" }])
                setColors([{ id: 1, color: "#D6D6D6" }, { id: 2, color: "#EAEAEA" }, { id: 3, color: "#EAEAEA" }])
                break;
            case 2:
                setTabs([{ id: 1, display: "none" }, { id: 2, display: "block" }, { id: 3, display: "none" }])
                setColors([{ id: 1, color: "#EAEAEA" }, { id: 2, color: "#D6D6D6" }, { id: 3, color: "#EAEAEA" }])
                break;
            case 3:
                setTabs([{ id: 1, display: "none" }, { id: 2, display: "none" }, { id: 3, display: "block" }])
                setColors([{ id: 1, color: "#EAEAEA" }, { id: 2, color: "#EAEAEA" }, { id: 3, color: "#D6D6D6" }])
                break;
        }
    }


    useEffect(()=>{
        if(adminDetailedWorks && adminDetailedWorks.detailedWorks.data[0].seo ){
            
            setTimeout(()=>{
                arabicEditor.current.set(adminDetailedWorks.detailedWorks.data[0].seo.description)
                englishEditor.current.set(adminDetailedWorks.detailedWorks.data[1].seo.description)
                frenchEditor.current.set(adminDetailedWorks.detailedWorks.data[2].seo.description)    
                
            },1000)
            
        }
    },[])

    const handleEditorChange = () => {
        formik.validateForm();
    }

    const validate = (values, props /* only available when using withFormik */) => {

        const errors = {};

        if (!values.englishTitle) {
            errors.englishTitle = 'Required';
        }

        if (!values.arabicTitle) {
            errors.arabicTitle = 'Required';
        }

        if (!values.frenchTitle) {
            errors.frenchTitle = 'Required';
        }

        if(englishEditor.current.log() == ""){
            errors.englishDescription = 'required'
        }

        if(frenchEditor.current.log() == ""){
            errors.frenchDescription = 'required'
        }

        if(arabicEditor.current.log() == ""){
            errors.arabicDescription = 'required'
        }

        return errors;
    };

    const getInitialState = () => {
        let initialState = {}
        if(adminDetailedWorks && adminDetailedWorks.detailedWorks.data[0].seo){
            adminDetailedWorks.detailedWorks.data.forEach((work)=>{
                switch(work.language){
                    case 'LANG_EN' : 
                        initialState = {...initialState,englishTitle:work.seo.title}
                        // englishEditor.current.set(work.seo.description)
                        break;
                    case 'LANG_FR' :
                        initialState = {...initialState,frenchTitle:work.seo.title}
                        // frenchEditor.current.set(work.seo.description)

                        break;
                    case 'LANG_AR' : 
                        initialState = {...initialState,arabicTitle:work.seo.title}
                        // arabicEditor.current.set(work.seo.description)
                        break
                }
            })

            // console.log('initiale state ',initialState);

            return initialState
        }else{
        return {
            englishTitle: '',
            frenchTitle: '',
            arabicTitle: '',
        }
        }
    }


    const formik = useFormik({
        initialValues: getInitialState(),
        validate,
        onSubmit: values => {
            // alert(JSON.stringify(values, null, 2));
            if(adminWork){
                // alert('on est dans le if');
                let request = {
                        workIdentifier:adminWork.response.data[0].workIdentifier,
                        englishTitle:values.englishTitle,
                        englishDescription:englishEditor.current.log(),
                        frenchTitle:values.frenchTitle,
                        frenchDescription:frenchEditor.current.log(),
                        arabicTitle:values.arabicTitle,
                        arabicDescription:arabicEditor.current.log()
                }

                // alert(JSON.stringify(request))

                addSeo(JSON.stringify(request)).then(
                    (response) => {
                        dispatch(loadAdminDetailedWorks(adminWork.response.data[0].workIdentifier))
                        .then(() => {
                            history.push('/admin/dashboard/works/add_work')
                        })
                        .catch(() => {
                        });
                    },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        console.log(_content)
                    }
                );

            }
        },
    });

    return (
        <>
            <div className="admin-section">
                <div className="add-wys-admin">
                    <div className="container-header">
                        <h2>Add SEO</h2>
                        <Link to="/admin/dashboard/works/add_work"><button className="button-add"><i className='bx bx-arrow-back'></i> Back</button></Link>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="tab-lang">
                            <div className="w3-bar">
                                <button type="button" className="w3-button" style={{ background: `${colors[0].color}` }} onClick={() => showTab(1)}>English {(formik.errors.englishTitle || formik.errors.englishDescription ? (<i className='bx bxs-error-circle' style={{color:'#f50b0b'}}  ></i>):null) }</button>
                                <button  type="button"  className="w3-button border-frensh" style={{ background: `${colors[1].color}` }} onClick={() => showTab(2)}>Frensh {(formik.errors.frenchTitle || formik.errors.frenchDescription ? (<i className='bx bxs-error-circle' style={{color:'#f50b0b'}}  ></i>):null) }</button>
                                <button  type="button"  className="w3-button" style={{ background: `${colors[2].color}` }} onClick={() => showTab(3)}>Arabic {(formik.errors.arabicTitle || formik.errors.arabicDescription ? (<i className='bx bxs-error-circle' style={{color:'#f50b0b'}}  ></i>):null) }</button>
                            </div>

                            <div className="tab-container">
                                <div id="English" style={{ display: `${tabs[0].display}` }} >
                                    <div className="add-dev-info ">
                                        <div className="url-dev add-seo-title" >
                                            <p>Title</p>
                                            <input
                                                id="englishTitle"
                                                name="englishTitle"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.englishTitle}
                                            />
                                            {formik.errors.englishTitle ? <div className="error">{formik.errors.englishTitle}</div> : null}
                                        </div>
                                        <div className="rish-editor"><RicheEditor ref={englishEditor} onchange={handleEditorChange}></RicheEditor></div>
                                        {formik.errors.englishDescription && <div className="error">{formik.errors.englishDescription}</div> }

                                    </div>
                                </div>

                                <div id="Frensh" style={{ display: `${tabs[1].display}` }}>
                                    <div className="add-dev-info ">
                                        <div className="url-dev add-seo-title">
                                            <p>Title</p>
                                            <input
                                                id="frenchTitle"
                                                name="frenchTitle"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.frenchTitle}
                                            />
                                            {formik.errors.frenchTitle ? <div className="error">{formik.errors.frenchTitle}</div> : null}
                                        </div>
                                        <div className="rish-editor"><RicheEditor ref={frenchEditor} onchange={handleEditorChange}></RicheEditor></div>
                                        {formik.errors.frenchDescription ? <div className="error">{formik.errors.frenchDescription}</div> : null}
                                    </div>
                                </div>

                                <div id="Arabic" style={{ display: `${tabs[2].display}` }}>
                                    <div className="add-dev-info ">
                                        <div className="url-dev add-seo-title">
                                            <p>Title</p>
                                            <input
                                                id="arabicTitle"
                                                name="arabicTitle"
                                                type="text"
                                                onChange={formik.handleChange}
                                                value={formik.values.arabicTitle}
                                            />
                                            {formik.errors.arabicTitle ? <div className="error">{formik.errors.arabicTitle}</div> : null}
                                        </div>
                                        <div className="rish-editor"><RicheEditor ref={arabicEditor} onchange={handleEditorChange}></RicheEditor></div>
                                        {formik.errors.arabicDescription ? <div className="error">{formik.errors.arabicDescription}</div> : null}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="alignement-button-fin-page">
                            <Link to="/admin/dashboard/works"><button className="button-fin-page"><i className='bx bxs-x-circle'></i> Cancel</button></Link>
                            {/* <Link to="/admin/dashboard/works"></Link> */}
                            <button className="button-fin-page" type="submit"><i className='bx bxs-save'></i> Save </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AddSeo;
