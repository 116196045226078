import React, {useState, useRef, useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from "react-i18next"


export default function Menu() {
 
    // useEffect(() => {
    //     const logo = useRef();
    // }, [])
    // ggghghghghghghghghgh
    const [activeNav, setActiveNav] = useState(false);
    const [logohover, setLogoHover] = useState(false);
    const [menuOpened, setMenuOpened] = useState(false);

    const activateNav = () => {
        if (window.scrollY >= 100) {
            setActiveNav(true);
        } else {
            setActiveNav(false);
        }
    }

    const handleMenuClick = () => {
        if (menuOpened) {
            setMenuOpened(false);
        } else {
            setMenuOpened(true);
        }
    }

    window.addEventListener('scroll', activateNav);


    const [prevScrollPos, setPrevScrollPos] = useState(0);

    // Navbar scroll manager 

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;
            const isVisible = prevScrollPos < currentScrollPos;

            setPrevScrollPos(currentScrollPos);

            const navbar = document.querySelector('.navbar');
            if (navbar) {
                if (isVisible && currentScrollPos > 400) {
                    navbar.classList.add('scroll-down');
                } else {
                    navbar.classList.remove('scroll-down');
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    // ****************************************

    const logoOnMouseEnter = () => {
        setLogoHover(true);
    }

    const logoOnMouseLeave = () => {
        setLogoHover(false);
    }

    const { t } = useTranslation();
    return (
        <>
            <div className="navigation-menu">
                <div className="nav-bg"></div>
                <div className="nav-bg2"></div>
                <div className="inner">
                    <address>
                        <span>{t('menu.adress.label')}</span>
                        <p>{t('menu.adress.adress.0')}<br/>
                        {t('menu.adress.adress.1')}<br/>
                        {t('menu.adress.adress.2')}</p>
                    </address>
                    <ul>
                        <li><Link to="/">{t('menu.links.0.link')}</Link></li>
                        <li><Link to="/about">{t('menu.links.1.link')}</Link></li>
                        <li><a href="/" className="services-link">{t('menu.links.2.link')}</a>
                            <ul>
                                <li><Link to="/software_development">{t('menu.links.2.sublinks.0')}</Link></li>
                                <li><Link to="/graphique_design">{t('menu.links.2.sublinks.1')}</Link></li>
                                <li><Link to="/production_video">{t('menu.links.2.sublinks.2')}</Link></li>
                            </ul>
                        </li>
                        <li><Link to="/works">{t('menu.links.6.link')}</Link></li>
                        <li><Link to="/faq">{t('menu.links.3.link')}</Link></li>
                        <li><Link to="/contact">{t('menu.links.4.link')}</Link></li>
                    </ul>
                </div>
            </div>
            <div className="transition-overlay">
                <div className="layer"></div>
            </div>
            {/* process.env.PUBLIC_URL + "/assets/images/logo.png" */}
            <nav className={activeNav ? 'navbar active' : 'navbar'}>
                <div className="logo">
                    <Link to="/" className="img-wrapper">
                        {/*onMouseEnter={logoOnMouseEnter} onMouseLeave={logoOnMouseLeave}*/}
                        {/* <img src={logohover ? process.env.PUBLIC_URL + "/assets/images/logo-h.png" : process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="Image" /> */}
                        <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} className="logoo" alt="Image"/>
                        <img src={process.env.PUBLIC_URL + "/assets/images/logo-h.png"} className="logo-h logoo"
                             alt="Image"/>
                    </Link>
                </div>
                <div className={menuOpened ? "sandwich-nav menuOpen" : "sandwich-nav"}>
                    <div className="sandwich-text">
                        <b>{t('menu.title')}<br/>{t('menu.close')}</b>
                    </div>
                    <div className="sandwich-btn" onClick={handleMenuClick} id="sandwich-btn"><span></span>
                        <span></span></div>
                </div>
            </nav>

        </>
    )
}
