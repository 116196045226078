import React,{useEffect} from 'react'
import { NavLink } from 'react-router-dom';

function Navigation() {

    let sidebar = document.querySelector(".sidebar");
    let closeBtn = document.querySelector("#btn");
    let searchBtn = document.querySelector(".bx-search");

    const moveSidebar = ()=> {
        console.log('btn clicked')
        sidebar.classList.toggle("open");
        menuBtnChange();//calling the function(optional)
    }

    const menuBtnChange = ()=>{
        if(sidebar.classList.contains("open")){
            closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
        }else {
            closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");//replacing the iocns class
        }
    } 

    // useEffect(() => {
    //     let sidebar = document.querySelector(".sidebar");
    //     let closeBtn = document.querySelector("#btn");
    //     let searchBtn = document.querySelector(".bx-search");

    //     closeBtn.addEventListener("click", ()=>{
    //                                 sidebar.classList.toggle("open");
    //                             menuBtnChange();//calling the function(optional)
    //     });

    //     searchBtn.addEventListener("click", ()=>{ // Sidebar open when you click on the search iocn
    //                                 sidebar.classList.toggle("open");
    //                             menuBtnChange(); //calling the function(optional)
    //     });

    //     // following are the code to change sidebar button(optional)
    //     function menuBtnChange() {
    //     if(sidebar.classList.contains("open")){
    //         closeBtn.classList.replace("bx-menu", "bx-menu-alt-right");//replacing the iocns class
    //     }else {
    //                                 closeBtn.classList.replace("bx-menu-alt-right", "bx-menu");//replacing the iocns class
    //     }
    //     }
    //     }, [])

    return (
        <>
            <div className="sidebar">
                <div className="logo-details">
                    {/* <i className='bx bxl-c-plus-plus icon'></i> */}
                    <img src={process.env.PUBLIC_URL + "/assets/images/admin-logo.png"} className="admin-logo" alt="IT ART Logo"/>
                    <div className="logo_name">ITArt</div>
                    <i className='bx bx-menu' id="btn" onClick={moveSidebar} ></i>
                </div>
                <ul className="nav-list">
                    <li>
                        <i className='bx bx-search' ></i>
                        <input type="text" placeholder="Search..." />
                        <span className="tooltip">Search</span>
                    </li>
                    <li>
                        <NavLink to="#">
                            <i className='bx bx-grid-alt'></i>
                            <span className="links_name">Dashboard</span>
                        </NavLink>
                        <span className="tooltip">Dashboard</span>
                    </li>
                    <li>
                        <NavLink to="/admin/dashboard/profile" activeClassName="active-link">
                            <i className='bx bx-user' ></i>
                            <span className="links_name">Profile</span>
                        </NavLink>
                        <span className="tooltip">Profile</span>
                    </li>
                    <li>
                        <NavLink to="/admin/dashboard/users-accounts" activeClassName="active-link">
                            <i className='bx bxs-user-account'></i>
                            <span className="links_name">Users-accounts</span>
                        </NavLink>
                        <span className="tooltip">Users-accounts</span>
                    </li>
                    {/* <li>
                        <NavLink to="#">
                            <i className='bx bx-chat' ></i>
                            <span className="links_name">Messages</span>
                        </NavLink>
                        <span className="tooltip">Messages</span>
                    </li> */}
                    <li>
                        <NavLink to="/admin/dashboard/works">
                            <i className='bx bx-pie-chart-alt-2' ></i>
                            <span className="links_name">Works</span>
                        </NavLink>
                        <span className="tooltip">Works</span>
                    </li>
                    <li>
                        <NavLink to="/admin/dashboard/gds">
                        <i className='bx bxs-pen'></i>
                            <span className="links_name">Graphique Design</span>
                        </NavLink>
                        <span className="tooltip">Graphique Design</span>
                    </li>
                    <li>
                        <NavLink to="#">
                            <i className='bx bx-cart-alt' ></i>
                            <span className="links_name">Order</span>
                        </NavLink>
                        <span className="tooltip">Order</span>
                    </li>
                    <li>
                        <NavLink to="#">
                            <i className='bx bx-heart' ></i>
                            <span className="links_name">Saved</span>
                        </NavLink>
                        <span className="tooltip">Saved</span>
                    </li>
                    <li>
                        <NavLink to="#">
                            <i className='bx bx-cog' ></i>
                            <span className="links_name">Setting</span>
                        </NavLink>
                        <span className="tooltip">Setting</span>
                    </li>
                    <li className="profile">
                        <div className="profile-details">
                            <img src="profile.jpg" alt="profileImg" />
                            <div className="name_job">
                                <div className="name">Prem Shahi</div>
                                <div className="job">Web designer</div>
                            </div>
                        </div>
                        <i className='bx bx-log-out' id="log_out" ></i>
                    </li>
                </ul>
            </div>
            
           


        </>
    )
}

export default Navigation
