export const setDetailedWork = (value) => {
    return {
        type: "SET_DETAILEDWORK",
        payload: value
    }
}

export const setAdminWork = (value) => {
    return {
        type: "SET_ADMINWORK",
        payload: value
    }
}

export const setAdminDetailedWorks = (value) => {
    return {
        type: "SET_ADMINDETAILEDWORKS",
        payload: value
    }
}

 