import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { useTranslation } from "react-i18next"

function PageNotFoundHeader() {

    const { t } = useTranslation();
    const playing = useSelector(state => state.Reducer.playing)
    const dispatch = useDispatch();

    return (
        <>
            <header className="page-header page-header-pageNotFound-page">
                <aside className="soundbar">
                    <div className="sound">
                        <div className="sound-title"> {t('pageNotFound.header.sound')}</div>
                        <div className={playing ? "equalizer" : "equalizer paused"}
                            onClick={() => dispatch({ type: 'TOGGLE' })}>
                            <div className="holder"><span></span> <span></span> <span></span> <span></span><span></span><span></span>
                            </div>
                        </div>
                        <div className={playing ? "sound-text" : "sound-text move-up"}><b>{t('pageNotFound.header.off')}<br />
                            {t('pageNotFound.header.on')}</b></div>
                    </div>
                </aside>
                <ul className="social-media">
                    <li><a href="https://www.facebook.com/Itart01" target="_blank">Facebook</a></li>
                    <li><a href="https://www.instagram.com/itart01/?hl=fr" target="_blank">Instagram</a></li>
                    <li><a href="https://dribbble.com/IT-ART" target="_blank">Dribbble</a></li>
                </ul>
                <div className="inner-content">
                    <div className="inner">
                        <div className="container">
                            <h2>{t('pageNotFound.header.title')}</h2>
                            <h4 className="couleur-subtitle"><strong>-</strong>{t('pageNotFound.header.subtitle')}</h4>
                        </div>
                    </div>

                </div>
            </header>
        </>
    )
}

export default PageNotFoundHeader
