const initaialState = {
    detailedWork: {detailedWork : {
        data : null
    }},
        // {
        // detailedWork: {
        //     data: {
        //         id: 75,
        //         workIdentifier: "23fe21c6-9149-4ffd-a823-77037007d51a",
        //         title: "qsdqsd",
        //         subtitle: "dfgfgf",
        //         language: "LANG_FR",
        //         images: {
        //             id: 23,
        //             cover: "https://firebasestorage.googleapis.com/v0/b/itart-2.appspot.com/o/rootFolder%2F42954934_346354542607338_5261529461345665665_n.jpg?alt=media&token=a4dc57b4-35fe-474b-b5b4-3e27ab6279a0",
        //             thumbnail: "https://firebasestorage.googleapis.com/v0/b/itart-2.appspot.com/o/rootFolder%2Fjs%20words%20encription.jpg?alt=media&token=6ee3f26d-f040-4c8f-a128-734b9fde8a7e"
        //         },
        //         visible: true,
        //         favorite: false,
        //         sections: [
        //             {
        //                 id: 9,
        //                 name: "VP"
        //             }
        //         ]

        //     }

        // }},
        adminWork: null,
        adminDetailedWorks: null
    }


export const WorkReducer = (state = initaialState, action) => {
        switch (action.type) {
            case "SET_DETAILEDWORK": {
                return { ...state, detailedWork: action.payload }
            }
            case "SET_ADMINWORK": {
                return { ...state, adminWork: action.payload }
            }
            case "SET_ADMINDETAILEDWORKS": {
                return { ...state, adminDetailedWorks: action.payload }
            }
            default:
                return state;
        }
    }