import React from 'react'
import Shimmer from './Shimmer'
import SkeltonElement from './SkeltonElement'

function SkeletonLogoTypeCard() {
  return (
    <>
        <div className="skelton-wrapper">
            <div className="skelton-logo-type-card">
                <SkeltonElement type="card-image" />
                <SkeltonElement type="title" />
                <SkeltonElement type="text" />
                <SkeltonElement type="text" />
                <SkeltonElement type="text" />
            </div>
            <Shimmer/>
        </div>
    </>
  )
}

export default SkeletonLogoTypeCard