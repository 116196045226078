import React from 'react'
import { PUBLIC_URL } from '../../Varialbles'


export default function Preloader() {
    return (
        <>
            <div className="preloader">
                <div className="layer">
                </div>
                <div className="inner">
                    <figure>
                        <img src={PUBLIC_URL + "/assets/images/loading.gif"} alt="Image"/>
                    </figure>
                    <span className="typewriter" id="typewriter"></span>
                </div>
            </div>
        </>
    )
}
