import React,{useEffect,useState} from 'react'
import Cookies from 'js-cookie'
import 'flag-icon-css/css/flag-icon.min.css'
import i18next from "i18next"
import $ from 'jquery'

const languages = [
    {
        code:'fr',
        name:'Français',
        country_code:'fr'
    },
    {
        code:'en',
        name:'English',
        country_code:'gb'
    },
    {
        code:'ar',
        name:'العربية',
        country_code:'ma',
        dir:'rtl '
    }
]


function LanguageSwitcher() {
    
    const [currentLangCode, setcurrentLangCode] = useState(Cookies.get('i18next') || 'fr')
    const currentLang = languages.find((l) => l.code === currentLangCode)
    
    const switchLanguage = (code)=>{
        i18next.changeLanguage(code);
        setcurrentLangCode(Cookies.get('i18next') || 'fr')
    }

    useEffect(() => {
        document.body.dir = currentLang.dir || 'ltr' 
        if(currentLang.code === 'ar'){
            document.body.style.textAlign = 'end'
            document.body.classList.add("arabic");

           // $("#who-are-we").after($("#about-content"));
           if($(window).width() > 768){

                setTimeout(()=>{
                const div1 = $('#who-are-we');
                const div2 = $('#about-content');
                
                const tdiv1 = div1.clone();
                const tdiv2 = div2.clone();
                
                
                    div1.replaceWith(tdiv2);
                    div2.replaceWith(tdiv1);
                },1000)	

            }  else{
                setTimeout(()=>{
                    const p1 = $('#pr1');
                    const p2 = $('#pr2');
                    
                    const tp1 = p1.clone();
                    const tp2 = p2.clone();
                    
                    
                        p1.replaceWith(tp2);
                        p2.replaceWith(tp1);
                    },1000)	
            }
            
            
        }else{
            document.body.style.textAlign = 'start'
            document.body.classList.remove("arabic");
           
        }
    }, [currentLang,currentLangCode])

    
    
    return (
        <>
            <div className="language_switcher">
                <div className="selected-language">
                    <span className={`flag-icon mr-2 flag-icon-${currentLang.country_code}`}></span>
                    <span className="mr-2">{currentLang.name}</span>
                    <i className="fas fa-sort-down dropdown-icon"></i>
                </div>
                <ul>
                    {languages.map(({code,name,country_code})=>{
                      

                        return country_code !== currentLang.country_code ?
                        <a href=""><li key={code}>
                            <div className="language" onClick={()=>switchLanguage(code)}> 
                                <span className={`flag-icon mr-2 flag-icon-${country_code}`}></span>
                                <span>{name}</span>
                                
                            </div>
                        </li></a>
                       :
                       ''
                    })}
                </ul>
            </div>
        </>
    )
}

export default LanguageSwitcher
