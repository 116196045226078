import React from 'react';
import { useTranslation } from 'react-i18next';

function Seo(props) {

  const {t} = useTranslation();

  return (
        <>
            <div className="seo-section">
               {props.staticTitle && <h2>{props.staticTitle}</h2>}
                {props.title && <h3>{props.title}</h3>}
                {props.description &&
                  <div className="wysiwyg-content">
                      <div dangerouslySetInnerHTML={{ __html: props.description }} />
                  </div>
                }
            </div>
        </>
  );
}

export default Seo;
