import {API_URL} from './../Varialbles';
import api from './api';

const getWorks = (lang) => {
    return api.get(API_URL + `/works/${lang}`);
};

const getFavoriteWorks = (lang) => {
    return api.get(API_URL + `/works/favorite/${lang}`);
};

const getDetailedWork = (id,lang) => {
    return api.get(API_URL + `/works/${id}/${lang}`);
};

const getDetailedWorks = (id) => {
    return api.get(API_URL + `/detailedworks/${id}`);
}

const addWork = (addWorkRequest) => {
    return api.post(API_URL + `/work`,addWorkRequest)
}

const addGraphicDesign = (request) => {
    return api.post(API_URL + `/works/graphic-design`,request)
}

const addWebDev = (request) => {
    return api.post(API_URL + `/works/web-development`,request)
}

const addVideoProduction = (request) => {
    return api.post(API_URL + `/works/video-production`,request)
}

const addSeo = (request) => {
    return api.post(API_URL + `/works/seo`,request)
}

const addDigitalMarketing = (request) => {
    return api.post(API_URL + `/works/digital-marketing`,request)
}

const deleteSection = (id,section) => {
    return api.delete(API_URL + `/works/${id}/${section}`)
}

const getWorksByIdetifier = (id) => {
    return api.get(API_URL + `/works/id/${id}`)
}

const deleteWork = (id) => {
    return api.delete(API_URL + `/works/${id}`)
}

const setWorkVisibility = (id,visible) =>{
    return api.post(API_URL + `/works/${id}/visible/${visible}`);
}

const setWorkFavority = (id,favorite) =>{
    return api.post(API_URL + `/works/${id}/favorite/${favorite}`);
}

const editWork = (request)=>{
    return api.put(API_URL + `/work`,request)
} 

const getWorksBySectionAndLanguage = (section,language)=>{
    return api.get(API_URL + `/works/section/${section}/language/${language}`)
} 



export {getWorks,getDetailedWork,addWork,addGraphicDesign,getDetailedWorks,addWebDev,addVideoProduction,addSeo,addDigitalMarketing,deleteSection,getWorksByIdetifier,deleteWork,setWorkVisibility,setWorkFavority,editWork,getFavoriteWorks,getWorksBySectionAndLanguage}