import { initializeApp } from "firebase/app";
import * as firebase from 'firebase';
import 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBTz4J20GxATKoBufnYF273FDVVnfStkUs",
  authDomain: "itart-2.firebaseapp.com",
  projectId: "itart-2",
  storageBucket: "itart-2.appspot.com",
  messagingSenderId: "14715249566",
  appId: "1:14715249566:web:294ea0bd1b41e035876c8f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = firebase.storage();
//export const db = firebase.firestore();
export const db = firebase.firestore();