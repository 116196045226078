import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux'
import SetPlaying from './../redux/Actions/SetPlaying'

const useAudio = () => {
    const [audio] = useState(new Audio(process.env.PUBLIC_URL + "assets/audio/audio.mp3"));
    const playing = useSelector(state => state.Reducer.playing);
    const dispatch = useDispatch();

    const toggle = () => dispatch({type: "TOGGLE"});

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        if (playing === true) {
            audio.play();
        }
        audio.addEventListener('ended', () => dispatch(SetPlaying(false)));
        return () => {
            audio.removeEventListener('ended', () => dispatch(SetPlaying(false)));
        }
    }, []);

    return [playing, toggle];
};

export default useAudio
