import React, { useState, useEffect, useRef } from 'react'
import { PUBLIC_URL } from './../../../../Varialbles'
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/styles/index.less';
import { Input, InputGroup, MaskedInput } from 'rsuite';
import './DesignProjectManager.css'
import { downloadBriefQuote, downloadCreativeBrief, getCreativeBriefs, updateNegotiationStatus, updateProductionStatus } from '../../../../services/design.service';
import { Button, IconButton, ButtonGroup, ButtonToolbar } from 'rsuite';
import ConfirmPopUp from '../../../tools/confirmPopUp/ConfirmPopUp';
import Cookies from 'js-cookie'
import axios from "axios";





function Graphique_Design() {

  const [date, setDate] = useState([new Date(), new Date()]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [projectName, setProjectName] = useState("");
  const [content, setContent] = useState(null);
  const [currentLangCode, setcurrentLangCode] = useState(Cookies.get('i18next') || 'fr')
  const [update,setUpdate] = useState(0);





  const [confirmDilog, setConfirmDialog] = useState({
    show: false,
    title: "",
    message: "",
    id: null
  })

  const search = (e) => {
    setProjectName(e)
  }

  function formatDate(_date) {
    var d = new Date(_date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }

  const negotiationStatusColumn = useRef(null);

  useEffect(() => {
    let startDate = formatDate(date[0]);
    let endDate = formatDate(Date(1));
    getCreativeBriefs(page, size, projectName, startDate, endDate).then(
      (response) => {

        console.log(response.data);
        setContent(response.data);
        // console.log(response.data)
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content)
        // setContent(_content);
      }

    );

    // let elements = document.querySelectorAll('.display-mode')
    // console.log(elements)
    // elements.forEach((elm) => {
    //   elm.addEventListener('click', function () {
    //     elm.classList.remove("display-mode")
    //     elm.classList.add("edit-mode")
    //   })
    // })

    // let editActions = document.querySelectorAll('.edit-mode .edit-mode-block i')
    // console.log(editActions)
    // editActions.forEach((elm) => {
    //   elm.addEventListener('click', function () {
    //     elm.parentElement.parentElement.classList.remove("edit-mode")
    //     elm.parentElement.parentElement.classList.add("display-mode")
    //   })
    // })

  }, [projectName, page, date,update])

  const negotiationStatus = [
    "LEAD",
    "NEGOTIATION",
    "PROPOSAL",
    "CLOSED",
    "LOST"
  ]

  const productionStatus = [
    "NEW_PROJECT",
    "IN_PROGRESS",
    "COMPLETED",
    "PENDING",
    "CANCELED",
    "OVERDUE",
    "UPCOMING"
  ]

  const switchToEditMode = (e) => {
    e.currentTarget.parentElement.classList.remove("display-mode")
    e.currentTarget.parentElement.classList.add("edit-mode")
  }

  const switchToDisplayMode = (e) => {
    e.currentTarget.parentElement.parentElement.classList.add("display-mode")
    e.currentTarget.parentElement.parentElement.classList.remove("edit-mode")
  }

  const handleCheckButtonClick = (id) => (e) => {
    let brief = content.creativeBriefs.filter((elm) => elm.id == id)[0]
    let status = e.target.parentElement.firstChild.value;
    console.log(brief)
    if (status == "CLOSED") {
      setConfirmDialog({
        show: true,
        title: "Warnning",
        message: "Are u sure you want to set this project status as closed, data will be sent to designers sheet and they could start working on the project since",
        id: id
      })
    }
    updateNegotiationStatus(id,{status}).then(
      (response) => {

        console.log("status updated");
        setUpdate(prev=>prev+1);
        //setContent(response.data);
        // console.log(response.data)
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content)
        // setContent(_content);
      }

    );

    switchToDisplayMode(e);
  }

  const sendDataToGoogleSheet = () => {
    downloadCreativeBrief(currentLangCode, confirmDilog.id).then(
      (response) => {

        let brief = content.creativeBriefs.filter((elm) => elm.id == confirmDilog.id)[0]

        let formData = new FormData();

        formData.append("projectName", brief.businessProjectInfo.businessName)
        formData.append("date", new Date())
        formData.append("deadline", brief.businessProjectInfo.deadline)
        formData.append("brief", response.data)
        formData.append("status", brief.productionStatus)

        let url = "https://script.google.com/macros/s/AKfycbzXRK_njp7Lumnmv0QoKgfkCJ0OtfhH4YQMsNXgUTfB7iVYyEo-RnG52O93iLeLhkokTQ/exec";

        axios.post(url, formData).then(
          (response) => {
            //setCreativeBriefDownloadLink(response.data);
            console.log(response)
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            alert(_content);
          }
        );
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);
      }
    );
  }

  const editStatus = (cofirm) => {
    if (cofirm) {
      console.log('send data to google sheet')
      sendDataToGoogleSheet();
      setConfirmDialog({
        show: false
      })
    } else {
      alert('canceled')
      setConfirmDialog({
        show: false
      })
    }
  }

  const editProductionStatus = (id) => (e)=>{
    let status = e.target.value;
    updateProductionStatus(id,{status}).then(
      (response) => {

        console.log("status updated");
        setUpdate(prev=>prev+1);
        //setContent(response.data);
        // console.log(response.data)
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content)
        // setContent(_content);
      }

    );
  }


  const handledownloadCreativeBrief =  (id) => {
    downloadCreativeBrief(currentLangCode,id).then(
      (response) => {
        window.open(response.data,'_blank')
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);
      }
    );

  }

  const handleBriefQuoteDownload = (id)=>{
    downloadBriefQuote(id).then(
      (response) => {
        window.open(response.data,'_blank')
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        alert(_content);
      }
    );
  }



  return (
    <>

      <div className="admin-section">

        <div className="manage-gd">
          <h2>Graphique Design Management</h2>
          <div className="gd-filter-bar">
            <DateRangePicker defaultValue={date} onOk={(e) => { setDate(e); }} />
            <InputGroup style={{ width: "200px" }}>
              <Input placeholder={"search"} size="sm" onChange={search} />
              <InputGroup.Addon>
                <i className='bx bx-search-alt-2'></i>
              </InputGroup.Addon>
            </InputGroup>

          </div>

          <div className="table-responsive">
            <table className="table-style table table-striped table-bordered table-sm">
              <thead>
                <tr>
                  <th scope="col">Project Name</th>
                  <th scope="col" className="gd-fullname">Date</th>
                  <th scope="col" className="gd-adress-mail">Negociation status</th>
                  <th scope="col" className="gd-tel">Production Status</th>
                  <th scope="col" className="gd-date">Quote</th>
                  <th scope="col" className="gd-statut">BC</th>

                </tr>
              </thead>
              <tbody>

                {content && content.totalItems > 0 && content.creativeBriefs?.map((cb) => {
                  return (
                    <tr>
                      <td className="align-middle"> <span>{cb.businessProjectInfo.businessName}</span> </td>
                      <td className="align-middle"><span>{cb._date}	</span> </td>
                      <td className="align-middle display-mode" ref={negotiationStatusColumn}>
                        {/* <select className="form-select" aria-label="Default select example"  >
                          { negotiationStatus.map((s)=>{
                              return (
                                <option value={s} selected={s == cb.negotiationStatus ? true : false}>{s}</option>
                              )
                            }) 
                          }

                        </select> */}
                        <div className="status-label" onClick={switchToEditMode} style={{ width: "100%", height: "100%" }}>{cb.negotiationStatus}</div>
                        <div className="edit-mode-block">
                          <select className="form-select" aria-label="Default select example"  >
                            {negotiationStatus.map((s) => {
                              return (
                                <option value={s} selected={s == cb.negotiationStatus ? true : false}>{s}</option>
                              )
                            })
                            }

                          </select>
                          <i class='bx bxs-x-circle' onClick={switchToDisplayMode} style={{ color: "red" }}></i>
                          <i className='bx bxs-check-circle' onClick={handleCheckButtonClick(cb.id)} style={{ color: "green" }}></i>
                        </div>
                      </td>
                      <td className="align-middle">
                        <select className="form-select" aria-label="Default select example" onChange={editProductionStatus(cb.id)}  >
                          {productionStatus.map((s) => {
                            return (
                              <option value={s} selected={s == cb.productionStatus ? true : false}>{s}</option>
                            )
                          })
                          }
                        </select>
                      </td>
                      <td>
                        <div className="container-button-gdd">
                          <button className="btn-container-gdd"><img src={PUBLIC_URL + "/assets/images/Work_Images/update.png"} className="manage-gd-button-table" /></button>
                          <button className="btn-container-gdd" onClick={()=>handleBriefQuoteDownload(cb.id)}> <img src={PUBLIC_URL + "/assets/images/Work_Images/download.png"} className="manage-gd-button-table" /></button>

                        </div> </td>



                      <td>
                        <button className="btn-container-gdd2" onClick={()=>handledownloadCreativeBrief(cb.id)}> <img src={PUBLIC_URL + "/assets/images/Work_Images/download.png"} className="manage-gd-button-table" /></button>
                      </td>
                    </tr>
                  )
                })}



              </tbody>
            </table>

          </div>


          {content &&
            <ButtonGroup>

              {[...Array(content.totalPages)].map((e, i) => {
                return (
                  <Button key={i} color={i == content.currentPage ? "blue" : ""} appearance={i == content.currentPage ? "primary" : ""} onClick={() => { setPage(i) }}>{i + 1}</Button>
                )
                // <div  onClick={() => pageClicked(i)}>{i + 1}</div>
              })}
            </ButtonGroup>}
        </div>
        {confirmDilog.show && <ConfirmPopUp title={confirmDilog.title} message={confirmDilog.message} confirm={editStatus} />}
      </div>
    </>
  )
}

export default Graphique_Design