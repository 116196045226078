import React, { useState, useEffect } from 'react';
import { getUsers,updateUser,deleteUser } from './../../../services/user.service';
import { useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import MultiSelect from "react-multi-select-component";
import { useForm } from "react-hook-form";
import updateUserForm  from './forms';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";


function UsersAccounts() {

    

    const { user: currentUser } = useSelector((state) => state.auth);

    const [successMessage,setSuccessMessage] = useState(null);
    const [errorMessage,setErrorMessage] = useState(null);

    const [content, setContent] = useState("");
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(3);
    const disableNext = (page + 1 === content.totalPages) ? true : false;
    const disablePrev = (page === 0) ? true : false;
    const [username, setUsername] = useState("");
    const [isOpen, setIsOpen] = React.useState(false);
    const [isModal2Open, setIsModal2Open] = React.useState(false);
    const [user,setUser] = useState({});
    const roles = [
        { label: "User", value: "ROLE_USER" },
        { label: "Admin", value: "ROLE_ADMIN" }
    ];
    
    ////////////////////////////////////////////////////////////
    // FORM VALIDATION

    const schema = yup.object().shape({
        username: yup.string().email().required(),
        password: yup.string().min(8).max(32).required(),
        confirmPassword : yup.string().oneOf([yup.ref("password")],"Confirm password should match password")
    });

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });
    
    const onSubmitHandler = (data) => {

        let userRoless = [];
        selected.map((role)=>{
            switch (role.label){
                case "Admin" : 
                    userRoless.push("admin");
                    break;
                case "User" : 
                    userRoless.push("user");
                    break;

            }
        })
        const userrr = {
            username : data.username,
            password : data.password,
            role : userRoless
        }

        updateUser(userrr,data.id).then(
            (response) => {
                setSuccessMessage("user updated successfuly !");
            },
            (error) => {
                const _content =(error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                setErrorMessage(_content);
            }
        );
        console.log({ data });
        console.log(userrr);
        console.log(selected);
        reset();
        hideModal();
      };

    // END FORM VALIDATION
    ////////////////////////////////////////////////////////////
    

    
    const [selected, setSelected] = useState([]);



    ////////////////////////////////////////////////////////////
    // Modal functions 

    const showModal = (user) => {
        let rolles = []
        user.roles.map((role)=>{
            if(role.name === "ROLE_USER"){
                rolles.push({label: "User",value: "ROLE_USER"})
            }else if (role.name === "ROLE_ADMIN"){
                rolles.push({label: "Admin",value: "ROLE_ADMIN"})
            }
        })
        reset();
        setSelected(rolles);
        setUser(user);
        setIsOpen(true);
    };

    const hideModal = () => {
        reset();
        setIsOpen(false);
    };
    
    const hideModal2 = () => {
        setIsModal2Open(false);
    };

    const showModal2 = (user)=>{
        setUser(user);
        setIsModal2Open(true);
    }


    // END MODAL FUNCTIONS
    ////////////////////////////////////////////////////////////


    ////////////////////////////////////////////////////////////
    //  PAGINATION
    
    const pages = [];

    for (let i = 0; i < content.totalPages; i++) {
        pages.push(i + 1);
    }

    const pageClicked = (pageNumber) => {
        setPage(pageNumber - 1);
    }

    const next = () => {
        setPage(page + 1);
    }

    const prev = () => {
        setPage(page - 1);
    }

    const search = (e) => {
        setUsername(e.target.value)
    }

    // EN PGINATION
    ////////////////////////////////////////////////////

    const _deleteUser = ()=>{
        deleteUser(user.id).then(
            (response)=>{
                setSuccessMessage("user deleted successfuly !")
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setErrorMessage(_content);
            }
        )
        hideModal2();
    }

    const returnDefaultValue=(inputName)=>{
        switch (inputName) {
            case "id" : 
                return user.id;
                break;
            case "username" :
                return user.username;
                break;
            default : 
                return ""
        }
    }
    
    useEffect(() => {

        getUsers(page, size, username).then(
            (response) => {
                setContent(response.data);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                        alert(_content)
                // setContent(_content);
            }
        );
    }, [page, username, successMessage]);

    // console.log(content)

    if (!currentUser) {
        return <Redirect to="/signin" />;
    }
    return (
        <>
            <div className="admin-section">
                <header className="jumbotron" style={{ color: '#fff', backgroundColor: '#0a2d50', borderRadius: 0 }}>
                    <h3>
                        <strong>Users accounts</strong>
                    </h3>
                </header>
                {successMessage && <div className="alert alert-primary alert-dismissible fade show">
                    {successMessage}
                    <button type="button" className="close" data-dismiss="alert">&times;</button>
                </div>}
                {errorMessage && <div className="alert alert-secondary alert-dismissible fade show">
                    {errorMessage}
                    <button type="button" className="close" data-dismiss="alert">&times;</button>
                </div>}
                <form className="form-inline">
                    <input className="form-control mr-sm-2" type="search" onChange={search} placeholder="Search" aria-label="Search" />
                </form>
                <div className="users-table-container">
                    <table className="table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Username</th>
                            <th>Password</th>
                            <th>role</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {content.users && content.users.map(user => {
                            return (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.username}</td>
                                    <td>****</td>
                                    <td>{user.roles[0]? user.roles[0].name : ""}</td>
                                    <td>
                                        <button className="btn btn-primary action-btn" onClick={()=>showModal(user)}><i className='bx bxs-edit-alt'></i></button>
                                        <button className="btn btn-danger action-btn" onClick={()=>showModal2(user)}><i className='bx bxs-trash-alt'></i></button>
                                    </td>
                                </tr>
                            )
                        })}
                        {!content.users && content}
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className="page-item"><button className="page-link" disabled={disablePrev} onClick={prev}>Previous</button></li>
                            {pages.map((pageNumber, i) => {
                                return (
                                    <li key={i} className={"page-item" + (pageNumber - 1 === page ? ' active' : '')}><button className="page-link" onClick={() => pageClicked(pageNumber)}>{pageNumber}</button></li>
                                )
                            })}
                            <li className="page-item"><button className="page-link" disabled={disableNext} onClick={next}>Next</button></li>

                        </ul>
                    </nav>
                    {/* {JSON.stringify(content)} */}
                    <Modal show={isOpen} onHide={hideModal}>
                        <Modal.Header>
                            <Modal.Title>Edit User</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={handleSubmit(onSubmitHandler)}>
                        <Modal.Body>
                            {updateUserForm.inputs.map((input,key)=>{
                                return (
                                    <div className="form-group row">
                                        <label for={input.name} className="col-sm-2 col-form-label">{input.label}</label>
                                        <div className="col-sm-10">
                                            <input {...register(input.name)} type={input.type} readonly={true} className={input.className} value={input.value ? returnDefaultValue(input.name) : null} id={input.name} defaultValue={returnDefaultValue(input.name)} />
                                            <p className="error">{errors[input.name]?.message}</p>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="form-group row">
                                <label for="roles" className="col-sm-2 col-form-label">Roles</label>
                                <div className="col-sm-10">
                                    <MultiSelect
                                        id="roles"
                                        options={roles}
                                        value={selected}
                                        onChange={setSelected}
                                        labelledBy="Select"
                                        hasSelectAll={false}
                                    />
                                            
                                </div>
                            </div>
                            
                            {/* <p>{result}</p> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-danger" onClick={hideModal}>Cancel</button>
                            <button className="btn btn-success">Save</button>
                        </Modal.Footer>
                        </form>
                    </Modal>
                    <Modal show={isModal2Open} onHide={hideModal2}>
                        <Modal.Header>
                            <Modal.Title>Are you sure you want to delete this user ?!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <button type="button" className="btn btn-secondary btn-lg" onClick={hideModal2}>Cancel</button>
                            <button type="button" className="btn btn-danger btn-lg" onClick={_deleteUser}>Delete user</button>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>

        </>
    )
}

export default UsersAccounts
