import React, { Fragment } from 'react'
import useAudio from './hooks/useAudio'
import Preloader from './components/home/Preloader'
import Menu from './components/Menu'
import {Route,Switch} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next'
import Signin from './components/securityComponent/Signin';
import ProtectedLoyouts from './louyouts/ProtectedLayouts';
import PublicLouyouts from './louyouts/PublicLayouts';
import PageNotFound from './components/p404/PageNotFound';


function AppWrapper() {

    const [playing, toggle] = useAudio();
    const {t} = useTranslation();
    return (
        <>
            <Helmet>
                <title>{t('seo_pages.overall.title')}</title>
                <meta name="description"
              content="Explorez agence marketing digitale multi services, spécialisées dans le développement de Web et d'applications mobiles, la création de logos et la production vidéo."/>
            <meta name="keywords"
             content="agence de création site web, meilleures agences à Casablanca, meilleures agences au Maroc, création site internet, création site web pas cher, agence création site internet, design application mobile, création application mobile pas cher, création application desktop, agence de développement web, agence de développement mobile, agence de production vidéo, agence de design graphique, agence de développement logiciel, création logo, agence création des logos, création carte de visite, agence de création des cartes de visite, création site web à Casablanca, création bannière, vidéo motion graphic, montage vidéo, shooting photos, montage photo, shooting photos à Casablanca, agence de digital, SEO, site web, animation graphique, logo, carte de visite, flyer, bannière, application mobile, application desktop, création vidéo, création de contenu"/>
            </Helmet>
            <Preloader/>
            {/* <Menu></Menu> */}
            <Switch>

                <Route path="/signin" exact component={Signin}/>
                <Route path="/admin"  component={ProtectedLoyouts}/>
                <Route path="/"  component={PublicLouyouts}/>
            </Switch>
        </>
    )
}

export default AppWrapper
