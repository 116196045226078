import React, { useState,useRef,useEffect } from 'react'
import { PUBLIC_URL } from './../../../Varialbles'
import { Link, useHistory } from 'react-router-dom'
import RicheEditor from './../../RicheEditor'
import { Modal, Button } from 'react-bootstrap'
import { VFSBrowser } from './../../admin/components/VFSBrowser'
import {useSelector, useDispatch} from 'react-redux'
import { addGraphicDesign } from '../../../services/work.service'
import { loadAdminDetailedWorks } from '../../../redux/Actions/WorkActions';



function Add_Graphique_Design() {

    const history = useHistory();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isOpen, setIsOpen] = React.useState(false);
    const [imgList, setImgList] = useState([]);
    const adminWork = useSelector(state => state.WorkReducer.adminWork);
    const adminDetailedWorks = useSelector(state => state.WorkReducer.adminDetailedWorks);
    const englishEditor = useRef();
    const frenchEditor = useRef();
    const arabicEditor = useRef();

    

    // const couter = 0

    const [Works,setWorks] = useState(null)

 
    const [tabs, setTabs] = useState([
        { id: 1, display: "block" },
        { id: 2, display: "none" },
        { id: 3, display: "none" }
    ])
    const [showErrorMessage,setShowErrorMessage] = useState(false);
    const [validation,setValidation] = useState({
        isValid:true,
        isEnglishValid:true,
        isFrenchValid:true,
        isArabicValid:true,
        isImagesLoaded:true,
        errorMassages:[]
    })
    const [colors, setColors] = useState([
        {
            id: 1,
            color: "#D6D6D6"
        },
        {
            id: 2,
            color: "#EAEAEA"
        },
        {
            id: 3,
            color: "#EAEAEA"
        }
    ])

    const showTab = (tab) => {
        switch (tab) {
            case 1:
                setTabs([{ id: 1, display: "block" }, { id: 2, display: "none" }, { id: 3, display: "none" }])
                setColors([{ id: 1, color: "#D6D6D6" }, { id: 2, color: "#EAEAEA" }, { id: 3, color: "#EAEAEA" }])
                break;
            case 2:
                setTabs([{ id: 1, display: "none" }, { id: 2, display: "block" }, { id: 3, display: "none" }])
                setColors([{ id: 1, color: "#EAEAEA" }, { id: 2, color: "#D6D6D6" }, { id: 3, color: "#EAEAEA" }])
                break;
            case 3:
                setTabs([{ id: 1, display: "none" }, { id: 2, display: "none" }, { id: 3, display: "block" }])
                setColors([{ id: 1, color: "#EAEAEA" }, { id: 2, color: "#EAEAEA" }, { id: 3, color: "#D6D6D6" }])
                break;
        }
    }

    

   

    // useEffect(()=>{

        // if(adminWork){
        //     dispatch(loadAdminDetailedWorks(adminWork.response.data[0].workIdentifier,'en'))
        //     .then(() => {
        //     })
        //     .catch(() => {
        //     });
        // }
        
    // },[adminWork])

    useEffect(()=>{
        if(adminDetailedWorks && adminDetailedWorks.detailedWorks.data[0].graphicDesign ){
            let images = []
            adminDetailedWorks.detailedWorks.data[0].graphicDesign.gallery.forEach(img => {
                images.push(img.url)
            }) 
            setTimeout(()=>{
                arabicEditor.current.set(adminDetailedWorks.detailedWorks.data[0].graphicDesign.description)
                englishEditor.current.set(adminDetailedWorks.detailedWorks.data[1].graphicDesign.description)
                frenchEditor.current.set(adminDetailedWorks.detailedWorks.data[2].graphicDesign.description)    
                setImgList(images)
            },1000)
            
        }
    },[])

    


    /**
     * chonky modal functions
     */
    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const insertImg = (url) => {
        setImgList(imgList.filter(item => item !== url))
        setImgList(imgList => [...imgList, url])
    }

    const removeImg = (img) => {
        if (window.confirm('Are you sure you wish to delete this item?')){
            setImgList(imgList.filter(item => item !== img))
        }
    }

    const validate = () => {
        let initialValidation = {isValid:true,isEnglishValid:true,isFrenchValid:true,isArabicValid:true,isImagesLoaded:true,errorMassages:[]}

        if(englishEditor.current.log() == ""){
            initialValidation = {...initialValidation,isValid:false,isEnglishValid:false,errorMassages:[...initialValidation.errorMassages,"Please fill the english description"]}
        }
        
        if(arabicEditor.current.log() == ""){
            initialValidation = {...initialValidation,isValid:false,isArabicValid:false,errorMassages:[...initialValidation.errorMassages,"Please fill the arabic description"]}
        }
        
        if(frenchEditor.current.log() == ""){
            initialValidation = {...initialValidation,isValid:false,isFrenchValid:false,errorMassages:[...initialValidation.errorMassages,"Please fill the french description"]}
        }
        
        if(imgList.length < 2){
            initialValidation = {...initialValidation,isValid:false,isImagesLoaded:false,errorMassages:[...initialValidation.errorMassages,"Please load at least 2 images"]}
        }

        setValidation(initialValidation);

        return initialValidation.isValid
        
    }

    const saveGraphicDesign = ()=>{
        
        if(validate()){
            // console.log(englishEditor.current.log())
            // console.log(arabicEditor.current.log())
            // console.log(frenchEditor.current.log())
            if(adminWork){
                // alert('data saved ')
                // console.log('admin work')
                let request = {
                    workIdentifier :adminWork.response.data[0].workIdentifier,
                    frenshDescription : frenchEditor.current.log(),
                    arabicDescription : arabicEditor.current.log(),
                    englishDescription : englishEditor.current.log(),
                    images : imgList
                }
                addGraphicDesign(JSON.stringify(request)).then(
                    (response) => {
                        dispatch(loadAdminDetailedWorks(adminWork.response.data[0].workIdentifier))
                        .then(() => {
                            history.push('/admin/dashboard/works/add_work')
                        })
                        .catch(() => {
                        });
                    },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
        
                        console.log(_content)
                        alert(_content)
                    }
                    );
                    
                    
                //console.log(adminWork.response.data[0].workIdentifier)
            }
        }
    }

    

    return (
        <>
            <div className="admin-section">
                <div className="add-gd-admin">
                    <div className="container-header">
                        <h2>Add Graphique Design</h2>
                        <Link to="/admin/dashboard/works/add_work"><button className="button-add"><i className='bx bx-arrow-back'></i> Back</button></Link>
                    </div>
                    <div className="wrapper-dg">
                        <p>Gallery :</p>
                        <div>
                            <button className="button-gallery" variant="primary" onClick={handleShow} type="button"><i className='bx bx-images'></i> Gallery ({imgList.length})</button> {!validation.isImagesLoaded && <i className='bx bxs-error-circle' style={{color:'#f60505'}} ></i>}
                            {/* Modal */}
                            <div className="modal-dg">
                                <Modal
                                    className="modalll"
                                    show={show}
                                    onHide={handleClose}
                                    
                                    dialogClassName="modall-90w"
                                    aria-labelledby="example-custom-modal-styling-title">

                                    <Modal.Header closeButton >
                                        <button className="Button-upload-modal" onClick={showModal}><i className='bx bxs-cloud-upload'></i>  Upload</button> 
                                        <span className="title-modal-p">Gallery</span>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="list-img-gallery-addgd">
                                            {(!(imgList.length == 0)) && imgList.map((img, idx) => {
                                                return (
                                                    <div key={idx} className="container-overlay-img-gallery">
                                                        <img src={img} className="img-gallery-addgd" alt="gallery-item" />
                                                        <div className="overlay-img-gallery">
                                                            <span className="trash-button-delete" onClick={()=>removeImg(img)} ><i className='bx bxs-trash'></i></span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="danger" onClick={handleClose}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {/* Fin Modal */}
                            </div>
                        </div>
                        <div className="tab-lang">
                            <div className="w3-bar">
                                <button className="w3-button" style={{ background: `${colors[0].color}` }} onClick={() => showTab(1)}>English {!validation.isEnglishValid && <i className='bx bxs-error-circle' style={{color:'#f60505'}} ></i>}</button>
                                <button className="w3-button border-frensh" style={{ background: `${colors[1].color}` }} onClick={() => showTab(2)}>Frensh {!validation.isFrenchValid && <i className='bx bxs-error-circle' style={{color:'#f60505'}} ></i>}</button>
                                <button className="w3-button" style={{ background: `${colors[2].color}` }} onClick={() => showTab(3)}>Arabic  {!validation.isArabicValid && <i className='bx bxs-error-circle' style={{color:'#f60505'}} ></i>}</button>
                            </div>
                            <div className="tab-container">
                                <div id="English" style={{ display: `${tabs[0].display}` }} >
                                    <p>Description</p>
                                    <div className="rish-editor"><RicheEditor ref={englishEditor}></RicheEditor></div>
                                </div>

                                <div id="Frensh" style={{ display: `${tabs[1].display}` }}>
                                    <p>Description</p>
                                    <div className="rish-editor"><RicheEditor ref={frenchEditor}></RicheEditor></div>
                                </div>

                                <div id="Arabic" style={{ display: `${tabs[2].display}` }}>
                                    <p>Description</p>
                                    <div className="rish-editor"><RicheEditor ref={arabicEditor}></RicheEditor></div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <ul>
                            {!validation.isValid && validation.errorMassages.map((err,idx)=>{
                                return (
                                    <li key={idx} className="error"><i className='bx bxs-message-rounded-error' style={{color:'#f60505'}} ></i>  {err}</li>
                                )
                            })}
                            
                        </ul>
                    <div className="alignement-button-fin-page">
                        <Link to="/admin/dashboard/works"><button className="button-fin-page"><i className='bx bxs-x-circle'></i> Cancel</button></Link>
                        <button className="button-fin-page" onClick={saveGraphicDesign}><i className='bx bxs-save'></i> Save </button>
                        {/* <Link to="/admin/dashboard/works"></Link> */}
                    </div>
                </div>
            </div>
            <Modal
                show={isOpen}
                onHide={hideModal}
                //size="lg"
                dialogClassName="modal-90w"
                centered
                fullscreen={true}
            >
                <Modal.Header>
                    <Modal.Title>FileManager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <VFSBrowser open={insertImg} close={hideModal}></VFSBrowser>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={hideModal}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Add_Graphique_Design
