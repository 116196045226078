import {API_URL} from './../Varialbles';
import api from './api';
import formDataApi from "./form-data-api"

const addLogoType = (request) => {
    return formDataApi.post(API_URL + `/creative-brief/logo-type`,request)                         
}

const addLikedLogo = (request) => {
    return formDataApi.post(API_URL + `/creative-brief/liked-logo`,request)                         
}



const getLogoTypes = () => {
    return api.get(API_URL + `/upload/logo-types`)                         
}

const getLogoType = (id) => {
    return api.get(API_URL + `/creative-brief/logo-type/${id}`)                         
}

const getLikedLogo = (id) => {
    return api.get(API_URL + `/creative-brief/liked-logo/${id}`)                         
}
const getLogoTypeNames = () => {
    return api.get(API_URL + `/creative-brief/logo-types/names`)                         
}

const deleteLogoType = (id) => {
    return api.delete(API_URL + `/creative-brief/logo-type/${id}`)                         
}
const updateLogoType = (id,request) => {
    return formDataApi.put(API_URL + `/creative-brief/logo-type/${id}`,request)                         
}

const getOrderedLikedLogos = (page = 0,size = 16,name) => {
    // console.log('auth header');
    // console.log(authHeader());
    return api.get(API_URL + "/upload/ordered-liked-logos" + `?page=${page}&size=${size}&name=${name}`);
};

const getServerRecods = ()=>{
    return api.get(API_URL + `/creative-brief/server-records`)   
}

const updateLikedLogo = (id,request)=>{
    return api.put(API_URL + `/creative-brief/liked-logo/edit/${id}`,request)
}

const updateLikedLogoOrder = (id,request)=>{
    return api.put(API_URL + `/creative-brief/liked-logo/edit-order/${id}`,request)
}

const daleteLikedLogo = (id)=>{
    return api.delete(API_URL + `/creative-brief/liked-logo/${id}`)
}

const formStep1Request = (request)=>{
    return api.post(API_URL + `/creative-brief-form/step1`,request);
}

const addBusinessProjectInfo = (request)=>{
    return api.post(API_URL + `/creative-brief-form/business-project-info`,request);
}

const uploadLogoStepFiles = (request) => {
    return formDataApi.post(API_URL + `/creative-brief-form/uploadMultipleLogoFiles`,request)                         
}

const addLogoDesignProject = (request)=>{
    return api.post(API_URL + `/creative-brief-form/LogoDesignProject`,request);
}

const downloadCreativeBrief = (lang,id)=>{
    return api.get(API_URL + `/creative-brief-form/download/${lang}/${id}`);
}

const updateUserProfile = (request)=>{
    return api.post(API_URL + `/creative-brief-form/step1/updateUserProfile`,request);
}


const getCreativeBriefs = (page = 0,size = 10,name,startDate,endDate) => {
    // console.log('auth header');
    // console.log(authHeader());
    return api.get(API_URL + "/creative-brief/projects" + `?page=${page}&size=${size}&projectName=${name}&startDate=${startDate}&endDate=${endDate}`);
};

const updateNegotiationStatus = (id,request)=>{
    return api.post(API_URL + `/creative-brief/negotiation-status/${id}`,request);
}

const updateProductionStatus = (id,request)=>{
    return api.post(API_URL + `/creative-brief/production-status/${id}`,request);
}

const downloadBriefQuote = (id) => {
    return api.get(API_URL + `/creative-brief/brief-quote/${id}`);
};


export {addLogoType,getLogoTypes,getLogoType,deleteLogoType,updateLogoType,addLikedLogo,getLikedLogo,getLogoTypeNames,getOrderedLikedLogos,getServerRecods,updateLikedLogo,updateLikedLogoOrder,daleteLikedLogo,formStep1Request,addBusinessProjectInfo,uploadLogoStepFiles,addLogoDesignProject,downloadCreativeBrief,updateUserProfile,getCreativeBriefs,updateNegotiationStatus,updateProductionStatus,downloadBriefQuote}