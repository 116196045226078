import DemoFsMap from '../../components/admin/components/demo/demo.fs_map.json'
import {FileData} from 'chonky';

// We define a custom interface for file data because we want to add some custom fields
// to Chonky's built-in `FileData` interface.
interface CustomFileData extends FileData {
    parentId?: string;
    childrenIds?: string[];
}
interface CustomFileMap {
    [fileId: string]: CustomFileData;
}


const baseFileMap = (DemoFsMap.fileMap as unknown) as CustomFileMap;
const rootFolderId = DemoFsMap.rootFolderId;

const initialState = {
    baseFileMap : baseFileMap,
    rootFolderId : rootFolderId
}


export const fileManagementReducer = (state = initialState, action:any) => {
    switch (action.type) {
        default :
            return state;
    }
}
