/**
 * this module is for Persisting Redux State to Local Storage
 * see https://stackoverflow.com/questions/35305661/where-to-write-to-localstorage-in-a-redux-app
 * see https://medium.com/@jrcreencia/persisting-redux-state-to-local-storage-f81eb0b90e7e
 * see https://egghead.io/lessons/javascript-redux-persisting-the-state-to-the-local-storage
 * @returns 
 */

const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if(serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (e) {
      return undefined;
    }
};
  
const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch (e) {
      // Ignore write errors;
    }
};

export {loadState,saveState};