import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { useTranslation } from "react-i18next"
import {Link} from 'react-router-dom'


function PageNotFound() {

    const { t } = useTranslation();
    const playing = useSelector(state => state.Reducer.playing)
    const dispatch = useDispatch();

    return (
        <>

            <section className="text-content-block page-not-found-section">
                <div className="container text-center">
                        <h1>{t('pageNotFound.content.title')}</h1>
                        <p>{t('pageNotFound.content.message.0')} <Link to="/">{t('pageNotFound.content.message.1')}</Link>{t('pageNotFound.content.message.2')}</p>
                </div>

            </section>
        </>
    )
}

export default PageNotFound
