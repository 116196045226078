import React, { useEffect, useState } from 'react'
import { getLogoTypes, deleteLogoType } from './../../../../services/design.service'
import { Link } from 'react-router-dom'
import SkeletonLogoTypeCard from '../../../tools/skeltons/SkeletonLogoTypeCard';
import ConfirmPopUp from '../../../tools/confirmPopUp/ConfirmPopUp';
import { set } from 'lodash';


function LogoTypesManager() {

    const [content, setContent] = useState(null);
    const [confirmDilog, setConfirmDialog] = useState({
        show: false,
        title: "",
        message: "",
        id: 1
    })

    const handleConfirmDialog = (show, title, message, id) => {
        setConfirmDialog({ show, title, message, id })
    }

    

    useEffect(() => {
        // console.log("in useffect")
        setTimeout(() => {
            getLogoTypes().then(
                (response) => {
                    setContent(response.data);
                    // console.log("Data : ", response.data)
                },
                // (error) => {

                // }
            ).catch((error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(_content);
            });
        }, 3000)
    }, []);

    const deleteLogoTypeTest = (confirm) => {
        if (confirm) {
            // alert("you sayed yes to " + confirmDilog.id)
            setConfirmDialog({ show: false })
            deleteLogoType(confirmDilog.id).then(
                (response) => {
                    getLogoTypes().then(
                        (response) => {
                            setContent(response.data);
                            console.log("Data : ", response.data)
                        },
                        (error) => {
                            const _content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();

                            setContent(_content);
                        }
                    );
                    return Promise.resolve();
                },
                (error) => {
                    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    console.log(message)
                    return Promise.reject();
                }
            )
        } else {
            // alert("you saied no to " + confirmDilog.id)
            setConfirmDialog({ show: false })
        }
    }

    // const deleteLogoTypeHandler = (id) => {
    //     if (window.confirm("are you sure you want to delete this logo type? all logos with this logo type will be deleted, if you want to delete only the logo type and save it's logos plz start by switching their types to another logo type before deleting this one")) {
            
    //     };
    // }


    return (
        <>
            <div className="admin-section">
                <div className="admin-logotypes-manager-container">
                    <Link style={{ float: "right" }} to="/admin/dashboard/gds/logo-types/add-logo-type">Add new logo type</Link> <h1>Logo types manager</h1>
                    <div className="admin-logo-types-container">
                        {content && content.map((lt, idx) => {
                            return (
                                <div className="m-slide" key={idx}>
                                    <img src={lt.imgUrl} alt="" />
                                    <div className="m-slide-content">
                                        <h3>{lt.id}  {lt.englishName}</h3>
                                        <p>{lt.englishDescription}</p>
                                        <i className='bx bxs-trash-alt m-icon m-icon-trash' onClick={() => handleConfirmDialog(true, "Warnning", "Are u sure?", lt.id)}></i>
                                        <Link to={`/admin/dashboard/design-projects/logo-types/edit-logo-type/${lt.id}`}><i className='bx bxs-edit-alt m-icon m-icon-edit'></i></Link>
                                    </div>

                                </div>
                            )
                        })}

                        {/* <SkeletonLogoTypeCard/> */}
                        {!content && [1, 2, 3, 4, 5].map((n) => {
                            return (
                                <SkeletonLogoTypeCard key={n} />
                            )
                        })}
                    </div>
                </div>
                {confirmDilog.show && <ConfirmPopUp title={confirmDilog.title} message={confirmDilog.message} confirm={deleteLogoTypeTest} />}
            </div>


        </>
    )
}

export default LogoTypesManager