import React from 'react'
import './Skeltons.css'

function SkeltonElement({type}) {

    const classes = `skelton ${type}`

  return (
    <>
        <div className={classes}></div>
    </>
  )
}

export default SkeltonElement