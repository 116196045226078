import React, { useEffect, useState } from 'react'
import { PUBLIC_URL } from './../../../Varialbles'
import { Link } from 'react-router-dom'
import { getWorks } from './../../../services/work.service';
import Cookies from 'js-cookie'
import {setAdminWork,setAdminDetailedWorks} from './../../../redux/Actions/WorkTypes'
import {useSelector, useDispatch} from 'react-redux'
import { useHistory } from "react-router-dom";
import { getAdminWorks,loadAdminDetailedWorks } from '../../../redux/Actions/WorkActions';
import { deleteWork,setWorkVisibility,setWorkFavority } from './../../../services/work.service';



function Works() {

    const [content, setContent] = useState(null);
    const [lang, setLang] = useState('en')
    const [currentLang, setCurrentLang] = useState(Cookies.get('i18next') || 'fr')
    const dispatch = useDispatch();
    const history = useHistory();


    const [sections,setSections] = useState([
        {id:1,tooltip:"Graphique&nbsp;Design",imgUrl:PUBLIC_URL + "/assets/images/Work_Images/gd.png",name:"GD"},
        {id:2,tooltip:"Marketing&nbsp;Digital",imgUrl:PUBLIC_URL + "/assets/images/Work_Images/md.png",name:"DM"},
        {id:3,tooltip:"Video&nbsp;Production",imgUrl:PUBLIC_URL + "/assets/images/Work_Images/pd.png",name:"VP"},
        {id:4,tooltip:"Seo",imgUrl:PUBLIC_URL + "/assets/images/Work_Images/seo.png",name:"SEO"},
        {id:5,tooltip:"Web Development",imgUrl:PUBLIC_URL + "/assets/images/Work_Images/web.png",name:"WD"}
    ])

    


    useEffect(() => {
        // console.log("in useffect")
        getWorks(lang).then(
            (response) => {
                setContent(response.data);
                console.log("Data : ", response.data)
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);
            }
        );
    }, []);

    const addWork = ()=>{
        dispatch(setAdminWork(null));
        dispatch(setAdminDetailedWorks(null));
        history.push("/admin/dashboard/works/add_work");
    }

    const editWork = (identifier)=>{
        dispatch(getAdminWorks(identifier)).then(()=>{
            dispatch(loadAdminDetailedWorks(identifier))
            .then(() => {
                history.push("/admin/dashboard/works/add_work");
            })
            .catch(() => {
                alert('somthing goes wrong plz try later');
            });
        }).catch(()=>{
            alert('somthing goes wrong plz try later');
        });
        
    }

    const deleteWorkk = (identifier) =>{
        if(window.confirm('are you sur you want to delete this work ?')){
            deleteWork(identifier).then(
                (response) => {
                    getWorks(lang).then(
                        (response) => {
                            setContent(response.data);
                            console.log("Data : ", response.data)
                        },
                        (error) => {
                            const _content =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
            
                            setContent(_content);
                        }
                    );
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(_content)
                    alert('something goes wrong plz try later');
                }
            );
        }
    }

    const editWorkVisibilty = (id,visibility)=>{
        //  alert(visibility)
        setWorkVisibility(id,visibility).then(
            (response) => {
                getWorks(lang).then(
                    (response) => {
                        setContent(response.data);
                        console.log("Data : ", response.data)
                    },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
        
                        setContent(_content);
                    }
                );
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(_content)
                alert('something goes wrong plz try later');
            }
        );
    }

    const editWorkFavority = (id,favority)=>{
        //  alert(favority)
        setWorkFavority(id,favority).then(
            (response) => {
                getWorks(lang).then(
                    (response) => {
                        setContent(response.data);
                        console.log("Data : ", response.data)
                    },
                    (error) => {
                        const _content =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
        
                        setContent(_content);
                    }
                );
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(_content)
                alert('something goes wrong plz try later');
            }
        );
    }





    return (
        <>


            <div className="admin-section">
                <div className="work-admin">
                    <div className="container-header">
                        <h2>Works</h2>
                        {/* <Link to="/admin/dashboard/works/add_work"></Link> */}
                        <button className="button-add" onClick={addWork}>+ Add</button>
                    </div>
                    <div className="table-responsive">
                        <table className="table-style table table-striped table-bordered table-sm" cellspacing="0" width="100%">
                            <thead>
                                <tr className="header-table">
                                    <th scope="col" className="fav-show-header"></th>
                                    <th scope="col" className="thumbnial-header"><span>thumbnail</span></th>
                                    <th scope="col" className="title-work-admin"><span>Project</span></th>
                                    <th scope="col" className="subtitle-work-admin"><span>Subtitle</span></th>
                                    <th scope="col" className="list-icons"><span>Services</span></th>
                                    <th scope="col" className="action-button-work"><span>Action</span></th>
                                </tr>
                            </thead>
                            <tbody>
                                {content &&
                                    content.map((work,index) =>  (
                                        <tr key={index} className="body-table">
                                            <th className="align-middle" scope="row">
                                                <div className="fav-show">
                                                    <button onClick={()=>editWorkVisibilty(work.workIdentifier,(!(work.visible)).toString())}>
                                                       {work.visible ? <i className='bx bxs-show icon'></i> : <i className='bx bx-show icon' ></i>}
                                                    </button>
                                                    <button onClick={()=>{editWorkFavority(work.workIdentifier,(!(work.favorite)).toString())}}>
                                                        {work.favorite ? <i className='bx bxs-heart icon' ></i> : <i className='bx bx-heart icon' ></i>}
                                                    </button>
                                                </div>
                                            </th>
                                            <td className="align-middle"><img src={work.images.thumbnail} className="thumblain-img" /></td>
                                            <td className="align-middle"><span className="project-name-span">{work.title}</span></td>
                                            <td className="align-middle"><span className="project-subtitle-span">{work.subtitle}</span></td>
                                            <td className="align-middle">
                                                <div className="container-icon-service-work">
                                                    {work.sections.map(function(s,idx){
                                                    let section = sections.find((section)=>{
                                                        return section.name == s.name
                                                    })
                                                    return (
                                                        <img key={idx} src={section.imgUrl} className="icon-service-work-img" />
                                                    )
                                                    })}
                                                    
                                                    {/* <img src={PUBLIC_URL + "/assets/images/Work_Images/web.png"} className="icon-service-work-img" />
                                                    <img src={PUBLIC_URL + "/assets/images/Work_Images/pd.png"} className="icon-service-work-img" />
                                                    <img src={PUBLIC_URL + "/assets/images/Work_Images/md.png"} className="icon-service-work-img" />
                                                    <img src={PUBLIC_URL + "/assets/images/Work_Images/seo.png"} className="icon-service-work-img" /> */}
                                                </div>
                                            </td>
                                            <td className="align-middle">
                                                <div className="list-button-maj">
                                                    <a href={`${PUBLIC_URL}/detailed-work/${work.workIdentifier}`} target="_blank" rel="noopener noreferrer" ><img src={PUBLIC_URL + "/assets/images/Work_Images/preview.png"} className="icon-button-maj"/></a>
                                                    <button className="admin-edit-button" onClick={()=>editWork(work.workIdentifier)}><img src={PUBLIC_URL + "/assets/images/Work_Images/update.png"} className="icon-button-maj" /></button>
                                                    <button className="admin-edit-button" onClick={()=>deleteWorkk(work.workIdentifier)}><img src={PUBLIC_URL + "/assets/images/Work_Images/delete.png"} className="icon-button-maj" /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                    
                                }

                                <tr className="body-table">
                                    {/* <th className="align-middle" scope="row">
                                    <div className="fav-show">
                                      <span><img src={PUBLIC_URL + "/assets/images/Work_Images/ep.png"} className="icon-show-fav" />
                                      </span>
                                      <span><img src={PUBLIC_URL + "/assets/images/Work_Images/he.png"} className="icon-show-fav" />
                                      </span>
                                      </div>
                                    </th>
                                    <td className="align-middle"><img src={PUBLIC_URL + "/assets/images/Olluminuim_cover.png"} className="thumblain-img" /></td>
                                    <td className="align-middle"><span className="project-name-span">olluminium</span></td>
                                    <td className="align-middle"><span className="project-subtitle-span">Menurisie and repration Allumium chez ollumium best price and quality  </span></td>
                                    <td className="align-middle">
                                        <div className="container-icon-service-work">
                                            <img src={PUBLIC_URL + "/assets/images/Work_Images/gd.png"} className="icon-service-work-img" />
                                            <img src={PUBLIC_URL + "/assets/images/Work_Images/web.png"} className="icon-service-work-img" />
                                            <img src={PUBLIC_URL + "/assets/images/Work_Images/pd.png"} className="icon-service-work-img" />
                                        </div>
                                    </td>
                                    <td className="align-middle">
                                        <div className="list-button-maj">
                                            <Link to="/"><img src={PUBLIC_URL + "/assets/images/Work_Images/preview.png"} className="icon-button-maj" /></Link>
                                            <Link to="/"><img src={PUBLIC_URL + "/assets/images/Work_Images/update.png"} className="icon-button-maj" /></Link>
                                            <Link to="/"><img src={PUBLIC_URL + "/assets/images/Work_Images/delete.png"} className="icon-button-maj" /></Link>
                                        </div>
                                    </td> */}
                                </tr>
                            </tbody>
                        </table>
                    </div>



                </div>
            </div>
        </>
    )
}

export default Works
