import React from 'react'
import './ConfirmPopUp.css'

function ConfirmPopUp({title,message,confirm}) {
  return (
    <>
        <div className="backdrop" onClick={()=>confirm(false)}>
            <div className="confirm-modal" onClick={(e)=>e.stopPropagation()}>
                <h2>{title}</h2>
                <p>{message}</p>
                <div className="confirm-buttons-container">
                    <button onClick={()=>confirm(true)}>yes</button>
                    <button onClick={()=>confirm(false)}>cancel</button>
                </div>
                
            </div>
        </div>
    </>
  )
}

export default ConfirmPopUp