import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom'
import WebDevWorkDetails from '../components/work/workDetail/WebDevWorkDetails';
// import About_content from '../components/about/About_content';
// import Contact_content from '../components/contact/Contact_content';
// import Faq_content from '../components/faq/Faq_content';
// import Software_development_content from '../components/servicesComponents/softwareDevelopment/Software_development_content';
// import Graphique_design_content from '../components/servicesComponents/graphicDesign/Graphic_design_content';
// import Production_video_content from '../components/servicesComponents/productionVideo/Production_video_content';
// import Header_production_video_page from '../components/servicesComponents/productionVideo/Header_production_video_page';
// import Header_software_development_page from '../components/servicesComponents/softwareDevelopment/Header_software_development_page';
// import Header_about_page from '../components/about/Header_about_page';
// import Contact_header from '../components/contact/Contact_header';
// import Header_faq_page from '../components/faq/Header_faq_page';
// import Home_content from '../components/home/Home_content';
// import Header from './../components/home/Header'
// import Footer from './../components/Footer'
// import Music from '../components/Music';
// import LanguageSwitcher from './../components/LanguageSwitcher'
// import SeoPage1 from './../components/seoPages/pages/SeoPage1';
// import SeoPage2 from './../components/seoPages/pages/SeoPage2';
// import SeoPage8 from './../components/seoPages/pages/SeoPage8';
// import SeoPage6 from './../components/seoPages/pages/SeoPage6';
// import SeoPage16 from './../components/seoPages/pages/SeoPage16';
// import SeoPage10 from './../components/seoPages/pages/SeoPage10';
// import SeoPage11 from './../components/seoPages/pages/SeoPage11';
// import SeoPage19 from './../components/seoPages/pages/SeoPage19';
// import SeoPage12 from './../components/seoPages/pages/SeoPage12';
// import SeoPage9 from './../components/seoPages/pages/SeoPage9';
// import SeoPage4 from './../components/seoPages/pages/SeoPage4';
// import SeoPage5 from './../components/seoPages/pages/SeoPage5';
// import SeoPage13 from './../components/seoPages/pages/SeoPage13';
// import SeoPage14 from './../components/seoPages/pages/SeoPage14';
// import SeoPage15 from './../components/seoPages/pages/SeoPage15';
// import SeoPage17 from './../components/seoPages/pages/SeoPage17';
// import SeoPage7 from './../components/seoPages/pages/SeoPage7';
// import SeoPage3 from './../components/seoPages/pages/SeoPage3';
// import SeoPage18 from './../components/seoPages/pages/SeoPage18';
// import Sitemap from './../components/sitemap/Sitemap';
// import Header_offre_page from './../components/offre/Header_offre_page';
// import Content_offer from './../components/offre/Content_offer';
// import Profile from './../components/securityComponent/Profile';
// import useAudio from '../hooks/useAudio';
// import Menu from '../components/Menu';
// import pageNotFoundHeader from '../components/p404/pageNotFoundHeader';
// import PageNotFound from '../components/p404/PageNotFound';
// import WorkPage from '../components/work/WorkPage'
// import HeaderWork from '../components/work/HeaderWork';
// import HeaderWorkDetail from '../components/work/workDetail/HeaderWorkDetail';
// import WorkDetail from '../components/work/workDetail/WorkDetail';
// import Header_thank_you from '../components/servicesComponents/graphicDesign/Header_thank_you';
// import CreativeBriefDownloader from '../components/servicesComponents/graphicDesign/CreativeBriefSuccess/CreativeBriefDownloader';
// import GraphicDesignWorkDetail from '../components/work/workDetail/GraphicDesignWorkDetail';

const Header_Graphic_Design = React.lazy(() => import('../components/servicesComponents/graphicDesign/Header_Graphic_Design'));

const About_content = React.lazy(() => import('../components/about/About_content'));
const Contact_content = React.lazy(() => import('../components/contact/Contact_content'));
const Faq_content = React.lazy(() => import('../components/faq/Faq_content'));
const Software_development_content = React.lazy(() => import('../components/servicesComponents/softwareDevelopment/Software_development_content'));
const Graphique_design_content = React.lazy(() => import('../components/servicesComponents/graphicDesign/Graphic_design_content'));
const Production_video_content = React.lazy(() => import('../components/servicesComponents/productionVideo/Production_video_content'));
const Header_production_video_page = React.lazy(() => import('../components/servicesComponents/productionVideo/Header_production_video_page'));
const Header_software_development_page = React.lazy(() => import('../components/servicesComponents/softwareDevelopment/Header_software_development_page'));
const Header_about_page = React.lazy(() => import('../components/about/Header_about_page'));
const Contact_header = React.lazy(() => import('../components/contact/Contact_header'));
const Header_faq_page = React.lazy(() => import('../components/faq/Header_faq_page'));
const Home_content = React.lazy(() => import('../components/home/Home_content'));
const Home_content2 = React.lazy(() => import('../components/home/Home_content2'));
const Header = React.lazy(() => import('../components/home/Header'));
const Header2 = React.lazy(() => import('../components/home/Header2'));
const Footer = React.lazy(() => import('../components/Footer'));
const Music = React.lazy(() => import('../components/Music'));
const LanguageSwitcher = React.lazy(() => import('../components/LanguageSwitcher'));
const SeoPage1 = React.lazy(() => import('../components/seoPages/pages/SeoPage1'));
const SeoPage2 = React.lazy(() => import('../components/seoPages/pages/SeoPage2'));
const SeoPage8 = React.lazy(() => import('../components/seoPages/pages/SeoPage8'));
const SeoPage6 = React.lazy(() => import('../components/seoPages/pages/SeoPage6'));
const SeoPage16 = React.lazy(() => import('../components/seoPages/pages/SeoPage16'));
const SeoPage10 = React.lazy(() => import('../components/seoPages/pages/SeoPage10'));
const SeoPage11 = React.lazy(() => import('../components/seoPages/pages/SeoPage11'));
const SeoPage19 = React.lazy(() => import('../components/seoPages/pages/SeoPage19'));
const SeoPage12 = React.lazy(() => import('../components/seoPages/pages/SeoPage12'));
const SeoPage9 = React.lazy(() => import('../components/seoPages/pages/SeoPage9'));
const SeoPage4 = React.lazy(() => import('../components/seoPages/pages/SeoPage4'));
const SeoPage5 = React.lazy(() => import('../components/seoPages/pages/SeoPage5'));
const SeoPage13 = React.lazy(() => import('../components/seoPages/pages/SeoPage13'));
const SeoPage14 = React.lazy(() => import('../components/seoPages/pages/SeoPage14'));
const SeoPage15 = React.lazy(() => import('../components/seoPages/pages/SeoPage15'));
const SeoPage17 = React.lazy(() => import('../components/seoPages/pages/SeoPage17'));
const SeoPage7 = React.lazy(() => import('../components/seoPages/pages/SeoPage7'));
const SeoPage3 = React.lazy(() => import('../components/seoPages/pages/SeoPage3'));
const SeoPage18 = React.lazy(() => import('../components/seoPages/pages/SeoPage18'));
const Sitemap = React.lazy(() => import('../components/sitemap/Sitemap'));
const Header_offre_page = React.lazy(() => import('./../components/offre/Header_offre_page'));
const Content_offer = React.lazy(() => import('./../components/offre/Content_offer'));
const Profile = React.lazy(() => import('./../components/securityComponent/Profile'));
// const useAudio = React.lazy(() => import('../hooks/useAudio'));
const Menu = React.lazy(() => import('../components/Menu'));
const pageNotFoundHeader = React.lazy(() => import('../components/p404/pageNotFoundHeader'));
const PageNotFound = React.lazy(() => import('../components/p404/PageNotFound'));
const WorkPage = React.lazy(() => import('../components/work/WorkPage'));
const HeaderWork = React.lazy(() => import('../components/work/HeaderWork'));
const HeaderWorkDetail = React.lazy(() => import('../components/work/workDetail/HeaderWorkDetail'));
const WorkDetail = React.lazy(() => import('../components/work/workDetail/WorkDetail'));
const Header_thank_you = React.lazy(() => import('../components/servicesComponents/graphicDesign/Header_thank_you'));
const CreativeBriefDownloader = React.lazy(() => import('../components/servicesComponents/graphicDesign/CreativeBriefSuccess/CreativeBriefDownloader'));
const GraphicDesignWorkDetail = React.lazy(() => import('../components/work/workDetail/GraphicDesignWorkDetail'));



const GHeader = () => {
    // const [playing, toggle] = useAudio();
    return (
        <Suspense>
            <Switch>
                <Route path="/" exact component={Header2} />
                <Route path="/graphique_design" exact component={Header_Graphic_Design} />
                <Route path="/production_video" exact component={Header_production_video_page} />
                <Route path="/software_development" exact component={Header_software_development_page} />
                <Route path="/about" exact component={Header_about_page} />
                <Route path="/contact" exact component={Contact_header} />
                <Route path="/faq" exact component={Header_faq_page} />
                <Route path="/works" exact component={HeaderWork} />
                <Route path="/detailed-work/:id" exact component={HeaderWorkDetail} />
                <Route path="/graphic-design/projects/:id" exact component={HeaderWorkDetail} />
                <Route path="/web-dev/projects/:id" exact component={HeaderWorkDetail} />
                <Route path="/offre" exact component={Header_offre_page} />
                <Route path="/creative-brief/download/:id" exact component={Header_thank_you} />
                <Route path="/Agence-infographie-Casablanca-Maroc" />
                <Route path="/Création-de-Site-Web-à-bon-prix-Casablanca-Maroc" />
                <Route path="/carte-de-visite-professionnelle-avec-logo" />
                <Route path="/Agence-de-communication-digitale-à-Casablanca-Maroc" />
                <Route path="/Création-de-site-web-Rabat" />
                <Route path="/Agence-de-création-d-applications-mobiles-à-Casablanca-Maroc" />
                <Route path="/Création-de-logo-à-bon-prix" />
                <Route path="/Agence-digitale-à-Casablanca-Maroc" />
                <Route path="/Design-et-impression-de-carte-visite-à-Casablanca-Maroc" />
                <Route path="/Création-de-site-web-casablanca" />
                <Route path="/Agence-de-développement-logiciel" />
                <Route path="/Agence-web-Casablanca-maroc" />
                <Route path="/Agence-de-production-audiovisuelle-à-Casablanca-Maroc" />
                <Route path="/Agence-motion-design" />
                <Route path="/Agence-de-montage-vidéo-à-Casablanca-Maroc" />
                <Route path="/Création-de-logo-à-bon-prix-casablanca-maroc" />
                <Route path="/Agence-de-motion-design-à-Casablanca-Maroc" />
                <Route path="/Agence-de-marketing-digital-à-Casablanca" />
                <Route path="/Agence-de-création-sites-web-et-applications-mobiles-Casablanca-Maroc" />
                <Route path="*" exact component={pageNotFoundHeader} />
            </Switch>
        </Suspense>
    );
}

const GContent = () => {
    return (
        <Suspense>
            <Switch>
                <Route path="/" exact component={Home_content2} />
                <Route path="/about" exact component={About_content} />
                <Route path="/contact" exact component={Contact_content} />
                <Route path="/faq" exact component={Faq_content} />
                <Route path="/software_development" exact component={Software_development_content} />
                <Route path="/graphique_design" exact component={Graphique_design_content} />
                <Route path="/production_video" exact component={Production_video_content} />
                <Route path="/carte-de-visite-professionnelle-avec-logo" exact component={SeoPage1} />
                <Route path="/Création-de-Site-Web-à-bon-prix-Casablanca-maroc" exact component={SeoPage2} />
                <Route path="/Design-et-impression-de-carte-visite-à-Casablanca-Maroc" exact component={SeoPage8} />
                <Route path="/Création-de-logo-à-bon-prix" exact component={SeoPage6} />
                <Route path="/Création-de-logo-à-bon-prix-casablanca-maroc" exact component={SeoPage16} />
                <Route path="/Agence-infographie-Casablanca-Maroc" exact component={SeoPage10} />
                <Route path="/Agence-de-développement-logiciel" exact component={SeoPage11} />
                <Route path="/Agence-de-création-sites-web-et-applications-mobiles-Casablanca-Maroc" exact component={SeoPage19} />
                <Route path="/Agence-web-Casablanca-maroc" exact component={SeoPage12} />
                <Route path="/Création-de-site-web-casablanca" exact component={SeoPage9} />
                <Route path="/Création-de-site-web-Rabat" exact component={SeoPage4} />
                <Route path="/works" exact component={WorkPage} />
                <Route path="/detailed-work/:id" exact component={WorkDetail} />
                <Route path="/graphic-design/projects/:id" exact component={GraphicDesignWorkDetail} />
                <Route path="/web-dev/projects/:id" exact component={WebDevWorkDetails} />
                <Route path="/offre" exact component={Content_offer} />
                <Route path="/creative-brief/download/:id" exact component={Header_thank_you} />
                <Route path="/Agence-de-création-d-applications-mobiles-à-Casablanca-Maroc" exact component={SeoPage5} />
                <Route path="/Agence-de-production-audiovisuelle-à-Casablanca-Maroc" exact component={SeoPage13} />
                <Route path="/Agence-motion-design" exact component={SeoPage14} />
                <Route path="/Agence-de-montage-vidéo-à-Casablanca-Maroc" exact component={SeoPage15} />
                <Route path="/Agence-de-motion-design-à-Casablanca-Maroc" exact component={SeoPage17} />
                <Route path="/Agence-digitale-à-Casablanca-Maroc" exact component={SeoPage7} />
                <Route path="/Agence-de-communication-digitale-à-Casablanca-Maroc" exact component={SeoPage3} />
                <Route path="/Agence-de-marketing-digital-à-Casablanca" exact component={SeoPage18} />
                <Route path="/profile" exact component={Profile} />
                <Route path="/plan-du-site" exact component={Sitemap} />
                <Route path="*" exact component={PageNotFound} />

            </Switch>
        </Suspense>
    );
}


function PublicLouyouts() {
    return (
        <>
            <Suspense>
                <Route path="/" component={Menu} />
                <LanguageSwitcher></LanguageSwitcher>
                <Route path="/creative-brief/download/:id" component={CreativeBriefDownloader} />
                <GHeader />
                {/* <Route path="/*" exact  component={pageNotFoundHeader}/> */}
                <main>
                    <GContent />
                    {/* <Route path="/*" exact component={PageNotFound}/> */}

                    <Route path="/" component={Footer} />
                </main>
                <Music />
            </Suspense>
        </>
    )
}

export default PublicLouyouts
