import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import {PUBLIC_URL} from './../../../Varialbles'
import { useTranslation } from 'react-i18next'

function WorkDW(props) {

    const {t} = useTranslation();

    useEffect(()=>{
        function resizeWebScreen(){
            var newWidth = document.getElementById("imgbx-id").offsetWidth;
            var newHeight = ( 815 / 982 ) * newWidth;
            document.getElementById("imgbx-id").setAttribute("style",`height:${newHeight}px`);
        }
        resizeWebScreen();
    },[])

    

    return (
        <>
            <div className="workDW">
                <h2>{t('workSections.WD.title')}</h2>
                <div className="bgcontainer">
                    <div className="container">
                        <div className="card">
                            <div className="imgBx imgBx2" id="imgbx-id">
                                <div className="screen-web dragscroll scrolll-2">
                                    {props.content && <img src={props.content.webSreenshot} alt="Hills" className="ecran_pc" />}
                                </div>
                            </div>
                            <div className="content">
                                <h5> {t('workSections.WD.desktop-version')}</h5>
                                {props.content &&  <h3>{props.content.webTitle}</h3>}
                               
                               {props.content && <p className="web-paragraph scrolll">{props.content.webDescription}</p>}
                            </div>
                        </div>
                        <div className="card card-marginn">
                            <div className="imgBx imgBx3 center-mobile">
                                <div className="mobile">
                                    <img src={PUBLIC_URL + "/assets/images/ecran_mobile.png"} alt="mobile" />
                                    <div className="screen-mobile dragscroll">
                                        <img className="mobile-speaker" src={PUBLIC_URL + "/assets/images/mobile-speaker.png"} alt="mobile-speaker" />
                                        {props.content && <img src={props.content.mobileScreenshot} alt="mobile-screenshot" />}
                                    </div>
                                </div>
                                {/*   <img src={process.env.PUBLIC_URL + "assets/images/ecran_mobile.png"} alt="Hills" className="ecran_mobile"/> */}
                            </div>
                            <div className="content mobile-content">
                             <h5>{t('workSections.WD.mobile-version')}</h5>
                             {props.content && <h3>{props.content.mobileTitle}</h3>}
                                
                                {props.content && <p className="mobile-paragraph scrolll">{props.content.mobileDescription}</p>}
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="see-more-button-container" style={{marginTop:"0"}}>
                   { props.content && props.content.siteUrl && <a href={props.content.siteUrl} data-swiper-parallax="-100" className="btn-ghost" target="_blank">
                        <span data-hover={t('workSections.WD.view-website')}>{t('workSections.WD.view-website')}</span>
                   </a> }
                </div>
                
            </div>

        </>
    )
}

export default WorkDW
