import React from 'react'
import authService from './../../../services/auth.service';
import { useDispatch } from "react-redux";
import {LOGOUT} from './../../../redux/Actions/securityActions/types'
function TopBar() {

    const dispatch = useDispatch();

    const logout = ()=>{
        authService.logout();
        dispatch({type:LOGOUT});
    }

    return (
        <>
            
            <section className="top-bar-section">
                <div className="top-bar">
                    <button className="logout-btn" onClick={logout}>
                        Sign out
                    </button>
                </div>
            </section>   
           
        </>
    )
}

export default TopBar
