import React from 'react'
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {LOGOUT} from './../../redux/Actions/securityActions/types'
import {logout} from './../../redux/Actions/securityActions/auth'
import authService from '../../services/auth.service';

function Profile() {

  const { user: currentUser } = useSelector((state) => state.auth);
  //  const user = JSON.parse(localStorage.getItem("user"));
  //  const { user: currentUser } = user ? {  user } : { user: null };
  const dispatch = useDispatch();

  if (!currentUser) {
    return <Redirect to="/signin" />;
  }

  // const logout = ()=>{
  //   authService.logout();
  //   dispatch({type:LOGOUT});
  // }

//console.log(currentUser)

  return (
    <div className="admin-section">
      <header className="jumbotron" style={{color: '#fff',backgroundColor: '#0a2d50',borderRadius:0}}>
        <h3>
          <strong>{currentUser.username}</strong> Profile
        </h3>
      </header>
      <div className="profile-content">
        <p>
          <strong>Token:</strong> {currentUser.token.substring(0, 20)} ...{" "}
          {currentUser.token.substring(currentUser.token.length - 20)}
        </p>
        <p>
          <strong>Id:</strong> {currentUser.id}
        </p>
        {/* <p>
          <strong>Email:</strong> {currentUser.email}
        </p> */}
        <strong>Authorities:</strong>
        <ul>
          {currentUser.roles &&
            currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
        </ul>
      </div>

      {/* <button className="nav-link" onClick={logout}>
        LogOut
      </button> */}
    </div>
  )
}

export default Profile
