import React from 'react'
import RicheEditor from './../../RicheEditor'
import FileManager from './FileManager'
import FileManager2 from './FileManager2'
import FirebaseUploader from './FirebaseUploader'
import FileManager3 from './FileManager3'
import { VFSBrowser } from './VFSBrowser'

function WorksTest() {
    return (
        <>
            <div className="admin-section">
                <header className="jumbotron" style={{ color: '#fff', backgroundColor: '#0a2d50', borderRadius: 0 }}>
                    <h3>
                        <strong>Works</strong>
                    </h3>
                </header>
                <RicheEditor></RicheEditor>
                <input name="image" type="file" id="upload" onchange=""></input>
                <br />
              
                <VFSBrowser></VFSBrowser>
            </div>
        </>
    )
}

export default WorksTest
