import {Provider} from 'react-redux'
import Store from './redux/Store'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import $ from 'jquery'
import {useEffect, useState} from 'react'
import AppWrapper from './AppWrapper';
import 'rsuite/dist/rsuite.min.css';


function App() {

    function randomBetween(range) {
        var min = range[0],
            max = range[1];
        if (min < 0) {
            return min + Math.random() * (Math.abs(min) + max);
        } else {
            return min + Math.random() * max;
        }
    }

    const [barsHeight,setBarsHeight] = useState([
        [2, 10],
        [5, 14],
        [11, 8],
        [1, 19],
        [9, 1],
        [13, 3]
    ]);

    

    useEffect(() => {
        setInterval(function () {
            $('.equalizer').find('span').each(function (i) {
                $(this).css({height: randomBetween(barsHeight[i]) + 'px'});
            });
        }, 250);


        // $('.works ul').isotope({
        //   itemSelector: '.works ul li',
        //   layoutMode: 'masonry'
        // });

    }, [])

    
    // wa youssef wa ta pulli mezyan

    return (
        <>
        <Router >
        <Provider
            store = {Store}>
        <AppWrapper/>
        </Provider>
        </Router>
        </>
)
    ;
}

export default App;

