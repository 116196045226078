import {getDetailedWork,addWork,getDetailedWorks,getWorksByIdetifier, editWork} from "./../../services/work.service"
import {setDetailedWork,setAdminDetailedWorks,setAdminWork} from "./WorkTypes";

export const loadDetailedWork = (id, lang) => (dispatch) => {
    return getDetailedWork(id, lang).then(
      (response) => {
        // alert('Im here')
        dispatch(setDetailedWork({detailedWork:response}));
        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return Promise.reject();
      }
    );
};

export const saveWork = (addWorkRequest) => (dispatch) => {
    return addWork(addWorkRequest).then(
      (response) => {
        dispatch(setAdminWork({response}));
        console.log('work added')
        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return Promise.reject();
      }
    )
};

export const persistWork = (request) => (dispatch) => {
  return editWork(request).then(
    (response) => {
      dispatch(setAdminWork({response}));
      console.log('work edited')
      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return Promise.reject();
    }
  )
}

export const getAdminWorks = (workIdentifier) => (dispatch) => {
  return getWorksByIdetifier(workIdentifier).then(
    (response) => {
      dispatch(setAdminWork({response}));
      console.log('work added')
      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return Promise.reject();
    }
  )
};

export const loadAdminDetailedWorks = (id) => (dispatch) => {
  return getDetailedWorks(id).then(
    (response) => {
      dispatch(setAdminDetailedWorks({detailedWorks:response}));
      return Promise.resolve();
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      return Promise.reject();
    }
  );
};