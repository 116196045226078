import React, { useState, useEffect } from 'react'
import Slider from 'react-touch-drag-slider'
import SimpleImageSlider from "react-simple-image-slider";
import useWindowDimensions from '../../../helpers/UseWindowDimentions';
import { useTranslation } from "react-i18next";
import $ from 'jquery'


function WorkGD(props) {

    const { t } = useTranslation();

    const images = props.images

    const { vWidth, vHeight } = useWindowDimensions();
    const sliderWidthPercentage = (percentage) => {
        return (vWidth * percentage) / 100;
    }

    const sliderHeight = () => {
        if (vWidth < 768) {
            return 250
        } else {
            return 500
        }
    }

    const sliderWidth = () => {
        if (vWidth < 768) {
            return sliderWidthPercentage(90)
        } else {
            return sliderWidthPercentage(70)
        }
    }



    return (
        <>
            <div className="WorkGD">
                <h2>{t('workSections.GD.title')}</h2>


                <div className="flex-img-test">
                    <img className="img-test-slide" src={process.env.PUBLIC_URL + "assets/images/Olluminuim_cover.png"} alt="" />
                    {/* <p className="p-workGD">
                    {props.description}
                    </p> */}
                    {props.description &&
                        <div className="wysiwyg-content">
                            <div dangerouslySetInnerHTML={{ __html: props.description }} className="graphic-design-html" />
                        </div>
                    }
                </div>

                {/*
                     slider documentation :
                     https://github.com/kimcoder/react-simple-image-slider
               */}

                {/* <div className="slider-wrapper">
                    <SimpleImageSlider
                        width={sliderWidth()}
                        height={sliderHeight()}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        navSize={50}
                        navStyle={2}
                        navMargin={20}
                    />
                </div> */}
                <div className="GD-images-wrapper">
                    {images.map((img,index)=>{
                        return ( 
                            <img key={index} src={img.url}></img>
                        )
                    })}
                </div>
                

                
            </div>
        </>
    )
}

export default WorkGD
