import React from 'react'
import { Link } from 'react-router-dom'
import "./css/GdsDashboard.css"

function GdsDashboard() {
    return (
        <>
            <div className="admin-section">
                <header className="gds-dashboard-header">
                    <img src={process.env.PUBLIC_URL + "/assets/images/GDS BG.jpg"} className="gds-bg" alt="GDS bg" />
                    <div className="gds-header-overlay"></div>
                    <div className="gds-logo-contaner">

                        <img src={process.env.PUBLIC_URL + "/assets/images/GDS Logo.png"} className="gds-logo" alt="GDS LOGO" />


                    </div>
                    <div className="statistics-container">

                        <div className="state">
                            <span className="state-number">08</span>
                            <span className="state-text">Logo Types</span>
                        </div>

                        <div className="state">
                            <span className="state-number">80</span>
                            <span className="state-text">Liked Logos</span>
                        </div>

                        <div className="state">
                            <span className="state-number">1.8k</span>
                            <span className="state-text">Total Breifs</span>
                        </div>

                        <div className="state">
                            <span className="state-number">1.2K</span>
                            <span className="state-text">Total prospects</span>
                        </div>

                    </div>

                </header>
                <nav className="gds-menu">
                    <ul>
                        <li className="gds-dashboard-link"> <Link to="/admin/dashboard/gds/logo-types/manager">Logo types</Link> </li>
                        <li className="gds-dashboard-link"> <Link to="/admin/dashboard/gds/liked-logos/manager">Liked logos</Link> </li>
                        <li className="gds-dashboard-link"> <Link to="/admin/dashboard/gds/manager">Projects managment</Link> </li>
                        <li className="gds-dashboard-link"> <a href="https://docs.google.com/spreadsheets/d/1y2AQIAxo4fPeI_wwBTjLYd-Yb9OshXtRwEJqGLkdU6Y/edit#gid=0" target="_blank">Producction sheet</a> </li>
                        <li className="gds-dashboard-link"> <a href="https://docs.google.com/spreadsheets/d/1TbrggkVvp-tZd-M7IfhAz51ulmNsS5oWFARuMF1mb50/edit#gid=0" target="_blank">Negotioation sheet</a> </li>
                    </ul>
                </nav>
            </div>

        </>
    )
}

export default GdsDashboard