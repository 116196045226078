import React, { useState, useEffect } from 'react'
import { addLikedLogo, getLikedLogo, getLogoTypeNames } from './../../../../services/design.service'
import { useForm } from "react-hook-form";

import { useHistory } from 'react-router-dom';
import Noty from 'noty';

function AddLikedLogo() {


  const [content, setContent] = useState(null);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const history = useHistory();

  const onSubmit = (data) => {

    const formData = new FormData();
    formData.append('file', data.file[0])
    formData.append('name', data.name)
    formData.append('type', data.type)
    console.log(data.type)
    

    addLikedLogo(formData).then(
      (response) => {

        new Noty({
          text: '<code>The logo type is added successfully!</code>',
          type: 'success',
          layout: 'top',
          theme: 'relax',
          timeout: 3000,
        }).show();
        history.push('/admin/dashboard/design-projects/liked-logos/manager')
        return Promise.resolve();
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        console.log(message)
        return Promise.reject();
      }
    )
  };

  useEffect(() => {
    getLogoTypeNames().then(
      (response) => {
        setContent(response.data);
        console.log("Data : ", response.data)
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log(_content);
      }
    );
  }, []);

  return (
    <>
      <div className="admin-section">
        <div className="add-like-logo-container">
          <h1>Add Logo</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="name">name</label>
              <input type="text" name="name"  {...register("name", { required: true })} />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <select name="type" id="type" {...register("type", { required: true })}>
                <option value=""></option>
                {content && content.map((n,idx)=>{
                  return (
                    <option value={n}>{n}</option>
                  )
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="file">Image</label>
              <input type="file" name="file" accept="image/*" {...register("file", { required: true })} />
            </div>
            <div className="error">
              {errors.name && <span>Name is required</span>} <br />
              {errors.type && <span>Type name is required</span>} <br />
              {errors.file && <span>image is required</span>} <br />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default AddLikedLogo