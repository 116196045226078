import React, { useState, useEffect } from 'react'
import { addLogoType, getLogoType,updateLogoType } from './../../../../services/design.service'
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import Noty from 'noty';



function AddLogoType() {
    // alert('add logo type')
    const { id } = useParams();
    const [content, setContent] = useState(null);
    const { register, handleSubmit, watch,setValue, formState: { errors } } = useForm();
    
    const history = useHistory();

    useEffect(() => {
        // console.log("in useffect")
        if (id) {
            getLogoType(id).then(
                (response) => {
                    setContent(response.data);
                    console.log("Data : ", response.data)
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setContent(_content);
                }
            );
        }
    }, []);

    useEffect(()=>{
        if(content){
        // setValue([
        //     { file: content.file }, 
        //     { arabicDescription: content.arabicDescription }, 
        //     { frenchDescription: content.frenchDescription }, 
        //     { englishDescription: content.englishDescription }, 
        //     { arabicName: content.arabicName }, 
        //     { frenchName: content.frenchName }, 
        //     { englishName: content.englishName }
        // ]);
        setValue("file",content.file);
        setValue("arabicDescription",content.arabicDescription);
        setValue("frenchDescription",content.frenchDescription);
        setValue("englishDescription",content.englishDescription);
        setValue("arabicName",content.arabicName);
        setValue("frenchName",content.frenchame);
        setValue("englishName",content.englishName);
        }

        // console.log(content.file);
    },[content])

    const onSubmit = (data) => {
        //   console.log(data)
        const formData = new FormData();
        if(data.file){
            formData.append('file', data.file[0])
        }
        formData.append('arabicDescription', data.arabicDescription)
        formData.append('frenchDescription', data.frenchDescription)
        formData.append('englishDescription', data.englishDescription)
        formData.append('arabicName', data.arabicName)
        formData.append('frenchName', data.frenchName)
        formData.append('englishName', data.englishName)
        if (!id) {
            addLogoType(formData).then(
                (response) => {
    
                    new Noty({
                        text:'<code>The logo type is added successfully!</code>',
                        type: 'success',
                        layout: 'top',
                        theme:'relax',
                        timeout: 3000,
                    }).show();
                    history.push('/admin/dashboard/design-projects/logo-types/manager')
                    return Promise.resolve();
                },
                (error) => {
                    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    console.log(message)
                    return Promise.reject();
                }
            )
        }else{
            updateLogoType(id,formData).then(
                (response) => {
                    new Noty({
                        text:'<code>The logo type is updatated successfully!</code>',
                        type: 'success',
                        layout: 'top',
                        theme:'relax',
                        timeout: 3000,
                    }).show();
                    history.push('/admin/dashboard/design-projects/logo-types/manager')
                    return Promise.resolve();
                },
                (error) => {
                    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    console.log(message)
                    return Promise.reject();
                }
            )
        }
        
        
    };

    return (
        <>
            <div className="admin-section">
                <div className="add-logo-type-container">
                    <h1>{id ? "Edit logo type" : "Add Logo type"}</h1>
                    {/* {id && <h2>{id}</h2>} */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label htmlFor="arabicName">Type Arabic name</label>
                            {/* defaultValue={content ? content.arabicName : ""} */}
                            <input type="text" name="arabicName"  {...register("arabicName", { required: true })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="frenchName">Type French name</label>
                            {/* defaultValue={content ? content.frenchame : ""} */}
                            <input type="text" name="frenchName"  {...register("frenchName", { required: true })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="englishName">Type English name</label>
                            {/* defaultValue={content ? content.englishName : ""} */}
                            <input type="text" name="englishName"  {...register("englishName", { required: true })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="arabicDescription">Arabic Description</label>
                            {/* defaultValue={content ? content.arabicDescription : ""} */}
                            <textarea name="arabicDescription" id="arabicDescription"  cols="30" rows="3" {...register("arabicDescription", { required: true })}></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="frenchDescription">French Description</label>
                            {/* defaultValue={content ? content.frenchDescription : ""} */}
                            <textarea name="frenchDescription" id="frenchDescription"  cols="30" rows="3" {...register("frenchDescription", { required: true })}></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="englishDescription">English Description</label>
                            {/* defaultValue={content ? content.englishDescription : ""} */}
                            <textarea name="englishDescription" id="englishDescription"  cols="30" rows="3" {...register("englishDescription", { required: true })}></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="file">Image</label>
                            {content && <img src={content.imgUrl} width="200" height={200} />}
                            <input type="file" name="file" accept="image/*" {...register("file", { required: id ? false : true })} />
                        </div>
                        <div className="error">
                            {errors.arabicName && <span>Arabic name is required</span>} <br />
                            {errors.frenchName && <span>French name is required</span>} <br />
                            {errors.englishName && <span>English name is required</span>} <br />
                            {errors.arabicDescription && <span>Arabic description is required</span>} <br />
                            {errors.frenchDescription && <span>French description is required</span>} <br />
                            {errors.englishDescription && <span>English description is required</span>} <br />
                            {errors.file && <span>image is required</span>} <br />

                        </div>
                        <button type="submit">Submit</button>
                    </form>


                </div>



            </div>
        </>
    )
}

export default AddLogoType