import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SetPlaying from '../../../redux/Actions/SetPlaying'
import { useTranslation } from 'react-i18next';

function WorkVP(props) {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    return (
        <>
            <div className="workDW">
            <h2>{t('workSections.VP.title')}</h2>
                <div className="video">
                    

                    {props.videoUrl && <iframe src={props.videoUrl} title="video player" frameBorder="0" autoplay="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} ></iframe>}

                    <div className="content-vp">
                         {props.subtitle && <h5>{props.subtitle}</h5>}
                        {props.title && <h3>{props.title}</h3>}
                        
                        {props.description &&
                            <div className="wysiwyg-content">
                                <div dangerouslySetInnerHTML={{ __html: props.description }} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkVP
