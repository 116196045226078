import axios from "axios";
import authHeader from "./auth-header";
import {API_URL} from './../Varialbles';
import api from './api';


const getUsers = (page = 0,size = 2,username) => {
    // console.log('auth header');
    // console.log(authHeader());
    return api.get(API_URL + "/users" + `?page=${page}&size=${size}&username=${username}`);
};

const updateUser = (user,id) => {
    // console.log('auth header');
    // console.log(authHeader());
    return api.put(API_URL + `/user/${id}`, user);
};

const deleteUser = (id)=>{
    return api.delete(API_URL + `/user/${id}`);
}
// + username !== '' ? `&username=${username}` : ''
export {getUsers,updateUser,deleteUser}