import React from 'react'
import {BrowserRouter, Link} from 'react-router-dom'
import { useTranslation } from "react-i18next";

export default function Footer() {

    const { t } = useTranslation();

    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div>
                        <Link to="/contact" data-swiper-parallax="-100" className="btn-ghost"><span
                            data-hover={t('footer.contact_us')}> {t('footer.contact_us')}</span></Link>
                    </div>
                    <div className="content-box wow fadeIn" data-wow-delay="0s">
                        <h2>{t('footer.adress')}</h2>
                        <p> {t('footer.adress_content.0.text')}<br/>
                        {t('footer.adress_content.1.text')}<br/>
                        {t('footer.adress_content.2.text')}</p>
                    </div>
                    <div className="content-box wow fadeIn" data-wow-delay="0.1s">
                        <h2>{t('footer.get_in_toutch')}</h2>
                        <p>+212 618 613 836<br/>
                            +212 612 884 185 <br/>
                            contact@itart.io</p>
                    </div>
                    <div className="content-box wow fadeIn" data-wow-delay="0.2s">
                        <h2>{t('footer.career')}</h2>
                        <p>{t('footer.career_content')}<br/>
                        Administration@itart.io  </p>
                    </div>
                </div>
                <hr />
                <div className="container">
                    <div className="quick-access">
                        <h2>{t('footer.access')}</h2>
                        <ul>
                            <li><Link to="/">{t('menu.links.0.link')}</Link></li>
                            <li><Link to="/about">{t('menu.links.1.link')}</Link></li>
                            <li><Link to="/software_development">{t('menu.links.2.sublinks.0')}</Link></li>
                            <li><Link to="/graphique_design">{t('menu.links.2.sublinks.1')}</Link></li>
                            <li><Link to="/production_video">{t('menu.links.2.sublinks.2')}</Link></li>
                            <li><Link to="/faq">{t('menu.links.3.link')}</Link></li>
                            <li><Link to="/contact">{t('menu.links.4.link')}</Link></li>
                            <li><Link to="/plan-du-site">{t('menu.links.5.link')}</Link></li>
                        </ul>
                    </div>
                    <div className="popular-searches">
                    <h2>{t('footer.search')}</h2>
                        <ul>
                    
                            <li><Link to="/carte-de-visite-professionnelle-avec-logo"> {t('seo_pages.pages.0.title')}</Link></li>
                            <li><Link to="/Création-de-Site-Web-à-bon-prix-Casablanca-Maroc"> {t('seo_pages.pages.1.title')}</Link></li>
                            <li><Link to="/Agence-de-communication-digitale-à-Casablanca-Maroc"> {t('seo_pages.pages.2.title')}</Link></li>
                            <li><Link to="/Création-de-site-web-Rabat"> {t('seo_pages.pages.3.title')}</Link></li>
                            <li><Link to="/Agence-de-création-d-applications-mobiles-à-Casablanca-Maroc"> {t('seo_pages.pages.4.title')}</Link></li>
                            <li><Link to="/Création-de-logo-à-bon-prix"> {t('seo_pages.pages.5.title')}</Link></li>
                            <li><Link to="/Agence-digitale-à-Casablanca-Maroc"> {t('seo_pages.pages.6.title')}</Link></li>
                            <li><Link to="/Design-et-impression-de-carte-visite-à-Casablanca-Maroc"> {t('seo_pages.pages.7.title')}</Link></li>
                            <li><Link to="/Création-de-site-web-casablanca"> {t('seo_pages.pages.8.title')}</Link></li>
                            <li><Link to="/Agence-infographie-Casablanca-Maroc"> {t('seo_pages.pages.9.title')}</Link></li>
                            <li><Link to="/Agence-de-développement-logiciel">  {t('seo_pages.pages.10.title')}</Link></li>
                            <li><Link to="/Agence-web-Casablanca-maroc"> {t('seo_pages.pages.11.title')} </Link></li>
                            <li><Link to="/Agence-de-production-audiovisuelle-à-Casablanca-Maroc"> {t('seo_pages.pages.12.title')}</Link></li>
                            <li><Link to="/Agence-motion-design"> {t('seo_pages.pages.13.title')}</Link></li>
                            <li><Link to="/Agence-de-montage-vidéo-à-Casablanca-Maroc"> {t('seo_pages.pages.14.title')} </Link></li>
                            <li><Link to="/Création-de-logo-à-bon-prix-casablanca-maroc"> {t('seo_pages.pages.15.title')}</Link></li>
                            <li><Link to="/Agence-de-motion-design-à-Casablanca-Maroc"> {t('seo_pages.pages.16.title')}</Link></li>
                            <li><Link to="/Agence-de-marketing-digital-à-Casablanca"> {t('seo_pages.pages.17.title')}</Link></li>
                            <li><Link to="/Agence-de-création-sites-web-et-applications-mobiles-Casablanca-Maroc"> {t('seo_pages.pages.18.title')}</Link></li>
                            
                        </ul>
                    </div>
                </div>
            </footer>
            <footer className="sub-footer">
                <div className="container wow fadeIn"><small>{t('footer.copyright')}</small>
                    <ul>
                        <li><a href="https://www.facebook.com/Itart01" target="_blank">Facebook</a></li>
                        <li><a href="https://www.instagram.com/itart01/?hl=fr" target="_blank">Instagram</a></li>
                        <li><a href="https://dribbble.com/IT-ART" target="_blank">Dribbble</a></li>
                    </ul>
                </div>
            </footer>
        </>
    )
}
