const updateUserForm = {
    inputs : [
        {
            label : "Id",
            name : 'id',
            type : 'text',
            className : 'form-control-plaintext',
            value : "id"
        },
        {
            label : "Username",
            name : 'username',
            type : 'text',
            className : 'form-control'
        },
        {
            label : "Password",
            name : 'password',
            type : 'password',
            className : 'form-control'
        },
        {
            label : "Confirm password",
            name : 'confirmPassword',
            type : 'password',
            className : 'form-control'
        }
    ]
}

export default updateUserForm ;