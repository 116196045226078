import { combineReducers } from "redux";
import auth from "./securityReducers/auth";
import message from "./securityReducers/message";
import { Reducer } from "./Reducer";
import { fileManagementReducer } from "./fileManagementReducers/fileManagementReducer";
import { WorkReducer } from "./WorkReducer";

export default combineReducers({
  Reducer,
  auth,
  message,
  WorkReducer,
  fileManagementReducer
});

