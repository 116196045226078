import React from 'react'
import {Route,Switch} from 'react-router-dom'
import AddWork from '../components/admin/components/AddWork'
import Add_Graphique_Design from '../components/admin/components/Add_Graphique_Design'
import UsersAccounts from '../components/admin/components/UsersAccounts'
import Add_Dev from '../components/admin/components/Add_Dev'
import Works from '../components/admin/components/Works'
import WorksTest from '../components/admin/components/WorksTest'
import Navigation from '../components/admin/dashboard/Navigation'
import TopBar from '../components/admin/dashboard/TopBar'
import Complete404page from '../components/p404/Complete404page'
import Profile from '../components/securityComponent/Profile'
import Add_Pv from '../components/admin/components/Add_Pv'
import Add_Wysywyg from '../components/admin/components/Add_Wysywyg'
import ProtectedRoute from '../components/securityComponent/ProtectedRoute'
import AddSeo from '../components/admin/components/AddSeo'
import DesignProjectsManager from '../components/admin/components/design_projects_manager/DesignProjectsManager'
import AddLogoType from '../components/admin/components/creativeBrief/AddLogoType'
import LogoTypesManager from '../components/admin/components/creativeBrief/LogoTypesManager'
import AddLikedLogo from '../components/admin/components/creativeBrief/AddLikedLogo'
import LikedLogoManager from '../components/admin/components/creativeBrief/LikedLogoManager'
import GdsDashboard from '../components/admin/components/creativeBrief/GdsDashboard'


const AdminPages = ()=>{
    return (
        <>
            <Route path="/admin/dashboard" component={Navigation}/>
            <Route path="/admin/dashboard" component={TopBar}/>
            <Route path="/admin/dashboard/profile" component={Profile}/>
            <Route path="/admin/dashboard/users-accounts" component={UsersAccounts}/>
            <Route path="/admin/dashboard/worksTest" component={WorksTest} />
            <Route path="/admin/dashboard/works" component={WorkPages} />
            <Route path="/admin/dashboard/gds" component={creativeBreif} />
        </>
    )
}

const WorkPages = ()=>{
    return (
        <>
            <Switch>
                <Route path="/admin/dashboard/works/add_wysywyg" component={Add_Wysywyg} />
                <Route path="/admin/dashboard/works/add-seo" component={AddSeo} />
                <Route path="/admin/dashboard/works/add_production_video" component={Add_Pv} />
                <Route path="/admin/dashboard/works/add_Development_web_and_mobile" component={Add_Dev} />
                <Route path="/admin/dashboard/works/add_graphique_design" component={Add_Graphique_Design} />
                <Route path="/admin/dashboard/works/add_work" component={AddWork} />
                <Route path="/admin/dashboard/works" component={Works} />
            </Switch>
        </>
    )
}

const creativeBreif = ()=>{
    return (
        <>
        <Switch>

                <Route path="/admin/dashboard/gds/manager" component={DesignProjectsManager} />
                
                
                <Route path="/admin/dashboard/gds/logo-types/edit-logo-type/:id" component={AddLogoType} />  
                <Route path="/admin/dashboard/gds/logo-types/add-logo-type" component={AddLogoType} />  
                <Route path="/admin/dashboard/gds/logo-types/manager" component={LogoTypesManager} />  
                <Route path="/admin/dashboard/gds/liked-logos/add-liked-logo" component={AddLikedLogo} />  
                <Route path="/admin/dashboard/gds/liked-logos/manager" component={LikedLogoManager} />  
                <Route path="/admin/dashboard/gds" component={GdsDashboard} />
        </Switch>
        </>
    )
}


function ProtectedLoyouts() {
    return (
        <>
            <Switch>
                <ProtectedRoute path="/admin/dashboard" component={AdminPages}/>
                <ProtectedRoute path="/admin*" component={Complete404page}/>
            </Switch>
        </>
    )
}

export default ProtectedLoyouts
