import React from 'react'

function Shimmer() {
  return (
    <>
        <div className="shimmer-wrapper">
          <div className="shimmer"></div>
        </div>
    </>
  )
}

export default Shimmer