import { set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react'
import { daleteLikedLogo, getLogoTypeNames, getOrderedLikedLogos, getServerRecods, updateLikedLogo, updateLikedLogoOrder } from '../../../../services/design.service';
import './css/LikedLogoManager.css'
import Noty from 'noty';
import ConfirmPopUp from '../../../tools/confirmPopUp/ConfirmPopUp';
import { Link } from 'react-router-dom'



function LikedLogoManager() {

    const serverInfo = useRef(null);
    const chevronIcon = useRef(null)
    const pageServerInfo = useRef(null);
    const pageChevronIcon = useRef(null)

    const toggleServerInfo = () => {
        serverInfo.current.classList.toggle('hidden-info');
        chevronIcon.current.classList.toggle('rotate');

    }
    const togglePageInfo = () => {
        pageServerInfo.current.classList.toggle('hidden-info');
        pageChevronIcon.current.classList.toggle('rotate');

    }

    const [page, setPage] = useState(0);
    const [size, setSize] = useState(16);
    const [content, setContent] = useState(null);
    const [name, setName] = useState("");
    const [types, setTypes] = useState(null);
    const [activeType, setActiveType] = useState("all");
    const [selectedLogo, setSelectedLogo] = useState("");
    const [serverRecords, setServerRecords] = useState(null)
    const [pageRecords, setPageRecords] = useState(0);
    const [nameInput,setNameInput] = useState("");
    const [typeInput,setTypeInput] = useState("");
    const [orderInput,setOrderInput] = useState("");
    const [formError,setFormError] = useState(null);
    const [update,setUpdate] = useState(0);

    const [confirmDilog, setConfirmDialog] = useState({
        show: false,
        title: "dqsdqs",
        message: "",
        id: 1
    })


    

    
   


    useEffect(() => {
        getLogoTypeNames().then(
            (response) => {
                setTypes(response.data);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(_content);
            }
        );

        getServerRecods().then(
            (response) => {
                setServerRecords(response.data);
                // console.log("Data : ", response.data)
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(_content);
            }
        );

    }, []);

    useEffect(()=>{
        const records = {}
        if(types){
            types.forEach((type) => {
                records[type] = 0
                
            })
        }
        if(content){
            content.likedLogos.forEach((logo)=>{
                records[logo.type] = records[logo.type] + 1;
            })
        }
        console.log(records);
        setPageRecords(records);
    },[types,content])

    


    useEffect(() => {
        getOrderedLikedLogos(page, size, name).then(
            (response) => {

                setContent(response.data);

                // console.log(response.data)
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                alert(_content)
                // setContent(_content);
            }
        );

    }, [name, page, update])





    const pageClicked = (pageNumber) => {
        setPage(pageNumber);
    }

    const search = (e) => {
        setName(e.target.value)
    }

    const handleFilterTypeClicked = (type) => {
        if (type)
            setActiveType(type)
        else
            setActiveType('all')


    }

    const getFilteredLogos = (type) => {
        if (type != "all") {
            return content.likedLogos.filter((logo) => { return logo.type == activeType });
        }
        else {
            return content.likedLogos;
        }
    }

    const logoClicked = (logo) => {
        setSelectedLogo(logo)
        setNameInput(logo.name);
        setOrderInput(logo.order);
        setTypeInput(logo.type);
        console.log(selectedLogo)
    }


    const handlesubmit = ()=>{
        let valid = true;
        if(!nameInput || !typeInput || !orderInput){
            valid = false
        }
        if(valid){
            updateLikedLogo(parseInt(selectedLogo.id),{name:nameInput,order:parseInt(orderInput),type:typeInput}).then(
                (response) => {
                    new Noty({
                        text: '<code>The logo type is updated successfully!</code>',
                        type: 'success',
                        layout: 'top',
                        theme: 'relax',
                        timeout: 3000,
                      }).show();
                    setUpdate(prev=>prev+1);

    
                    // console.log(response.data)
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content)
                    // setContent(_content);
                }
            );
            setFormError(null)
        }
        else{
            setFormError("All feilds are required to edit logo");
        }
    }

    const moveLogo = (id,order,currentOrder,move)=>{
        console.log(parseInt(order));
        if((parseInt(currentOrder) > 1 && move == "up") || parseInt(currentOrder) < content.totalItems && move == "down"){
            updateLikedLogoOrder(parseInt(id),{order:parseInt(order)}).then(
                (response) => {
                    new Noty({
                        text: '<code>The logo type is updated successfully!</code>',
                        type: 'success',
                        layout: 'top',
                        theme: 'relax',
                        timeout: 3000,
                      }).show();
                    setUpdate(prev=>prev+1);
    
    
                    // console.log(response.data)
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content)
                    // setContent(_content);
                }
            );
        }else{
            alert("logo out of boudaries");
        }
        
    }

    const deleteLogo = (confirm)=>{
        if(confirm){
            daleteLikedLogo(parseInt(confirmDilog.id)).then(
                (response) => {
                    new Noty({
                        text: '<code>The logo type is updated successfully!</code>',
                        type: 'success',
                        layout: 'top',
                        theme: 'relax',
                        timeout: 2000,
                      }).show();
                      setConfirmDialog({show:false})
                    setUpdate(prev=>prev+1);
    
    
                    // console.log(response.data)
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    alert(_content)
                    // setContent(_content);
                }
            );
        }else{
            // alert("you said no")
            setConfirmDialog({show:false})
        }
    }
    



    return (
        <>
            <div className="admin-section">
                <div className="liked-logo-manager-container">
                    <h1>Liked Logo Manager</h1>
                    <div className="liked-logo-grid-wrapper">
                        <div className="filter-bar">
                            <div className="name-filter">
                                <label htmlFor="name-filter">Name : </label>
                                <input name="name-filter" type="search" onChange={search} placeholder="Search" />
                            </div>
                            <div className="type-filter">
                                <ul>
                                    <li className={activeType == "all" ? "active" : ""} onClick={() => handleFilterTypeClicked(null)}>All</li>
                                    {types && types.map((typee, i) => {
                                        return <li key={i} className={activeType == typee ? "active" : ""} onClick={() => handleFilterTypeClicked(typee)}>{typee}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="liked-logo-listing">
                            {content && getFilteredLogos(activeType).length == 0 && <div className="no-content">
                                No logos match filters
                            </div>}

                            {content && getFilteredLogos(activeType).map((logo, i) => {
                                return (
                                    <div key={logo.order} className={logo.order == selectedLogo.order ? "liked-logo active" : "liked-logo"} onClick={() => logoClicked(logo)}>
                                        <img src={logo.url} alt={logo.name} />
                                        <div className="overlay" >
                                            <i className='bx bxs-chevron-left-circle arrow' onClick={()=>moveLogo(logo.id,logo.order-1,logo.order,"up")} ></i>
                                            <i className='bx bxs-trash-alt trash' onClick={() => setConfirmDialog({show:true,title:"Attention",message: "Are u sure you want to delete this item ?", id:logo.id})}></i>
                                            <i className='bx bxs-chevron-right-circle arrow' onClick={()=>moveLogo(logo.id,logo.order+1,logo.order,"down")}></i>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="right-sidebar">
                            <div className="pagination-container">

                                {content && [...Array(content.totalPages)].map((e, i) => {
                                    return <div key={i} className={i == content.currentPage ? "page active" : "page"} onClick={() => pageClicked(i)}>{i + 1}</div>
                                })}
                            </div>
                            <div className="server-info">
                                <div className="server-info-title" onClick={toggleServerInfo}>
                                    Server records info <i ref={chevronIcon} className='bx bx-chevron-up'></i>
                                </div>
                                {serverRecords && <div className="server-info-content" ref={serverInfo}>
                                    {Object.keys(serverRecords).map((keyName, keyIndex) => {
                                        return (
                                            <>
                                                <div className="type">{keyName}</div>
                                                <div className="value">{serverRecords[keyName]}</div>
                                            </>
                                        )
                                    })}


                                </div>}
                            </div>
                            <div className="server-info">
                                <div className="server-info-title" onClick={togglePageInfo}>
                                    Page records info <i ref={pageChevronIcon} className='bx bx-chevron-down'></i>
                                </div>
                                {pageRecords && <div className="server-info-content hidden-info" ref={pageServerInfo}>
                                {Object.keys(pageRecords).map((keyName, keyIndex) => {
                                        return (
                                            <>
                                                <div className="type">{keyName}</div>
                                                <div className="value">{pageRecords[keyName]}</div>
                                            </>
                                        )
                                    })}
                                   
                                </div>}
                            </div>
                            {selectedLogo && <div className="edit-logo-form-container">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="">Name</label>
                                        <input type="text" value={nameInput} onChange={e=>setNameInput(e.target.value)} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Type</label>
                                        <select name="" id="" value={typeInput} onChange={e=>setTypeInput(e.target.value)}>
                                            {types && types.map((type, i) => {
                                                return (
                                                    <option  key={i} value={type}>{type}</option>
                                                )
                                            })}
                                            

                                        </select>
                                        {/* selected={type == selectedLogo.type} */}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="">Order</label><br />
                                        <input type="number" value={orderInput} style={{width:"100%"}} min="1" max={content.totalItems} onChange={e=>setOrderInput(e.target.value)} />
                                    </div>
                                    {formError && <div className="error">{formError}</div> }
                                    <div className="edit-logo-btn-container">
                                        <button type="button" onClick={handlesubmit} className="edit-logo-btn">submit</button>
                                    </div>

                                </form>
                            </div>}
                            <Link to="/admin/dashboard/gds/liked-logos/add-liked-logo">Add Logo</Link>
                        </div>
                    </div>

                </div>
                {confirmDilog.show && <ConfirmPopUp title={confirmDilog.title} message={confirmDilog.message} confirm={deleteLogo} />}
            </div>
        </>
    )
}

export default LikedLogoManager