import React,{useEffect, useState} from 'react'
import WorkDW from './WorkDW'
import WorkGD from './WorkGD'
import WorkVP from './WorkVP'
import Seo from './Seo'
import {useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

function WebDevWorkDetails() {

    const [currentLangCode, setcurrentLangCode] = useState(Cookies.get('i18next') || 'fr')
    const {t} = useTranslation();
    const detailedWork = useSelector(state => state.WorkReducer.detailedWork.detailedWork.data);
    const images = [];
    if(detailedWork != null && detailedWork.graphicDesign !== null){
        detailedWork.graphicDesign.gallery.forEach((img)=>{
            images.push({url:img.url})
        })
    }
    const [webDevContent, setWebDevContent] = useState(null);

    console.log("images : ",images)
    console.log("detailed work : ",detailedWork)

    useEffect(()=>{
        if(detailedWork != null && detailedWork.webDev !== null){
            setWebDevContent( {
               siteUrl : detailedWork.webDev.siteUrl,
               webTitle : detailedWork.webDev.webTitle,
               webDescription : detailedWork.webDev.webDescription,
               mobileTitle : detailedWork.webDev.mobileTitle,
               mobileDescription : detailedWork.webDev.mobileDescription,
               webSreenshot : detailedWork.webDev.images.webScreenshot,
               mobileScreenshot : detailedWork.webDev.images.mobileScreenshot
           })
       }
    },[detailedWork])

    if(detailedWork != null && detailedWork.videoProduction){
        console.log("video url : ",detailedWork.videoProduction.videoUrl )
    }

  return (
    <>
        <section  dir={currentLangCode==="ar"? "rtl":""} className="detailwork">
                {/* {detailedWork != null && detailedWork.graphicDesign && images.length > 0 && <WorkGD images={images} description={detailedWork.graphicDesign.description}></WorkGD>} */}
                {detailedWork != null && detailedWork.webDev && <WorkDW content={webDevContent}></WorkDW>}
                {/* {detailedWork != null && detailedWork.videoProduction && <WorkVP videoUrl={detailedWork.videoProduction.videoUrl} title={detailedWork.videoProduction.title} subtitle={detailedWork.videoProduction.subtitle} description={detailedWork.videoProduction.description} ></WorkVP>} */}
                {/* {detailedWork != null && detailedWork.seo && <Seo staticTitle={t('workSections.SEO.title')} title={detailedWork.seo.title} description={detailedWork.seo.description}></Seo>} */}
                {/* {detailedWork != null && detailedWork.digitalMarketing && <Seo staticTitle={t('workSections.DM.title')} title={detailedWork.digitalMarketing.title} description={detailedWork.digitalMarketing.description}></Seo>} */}
        </section>
    </>
  )
}

export default WebDevWorkDetails