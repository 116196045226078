import {createStore,applyMiddleware } from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import mainReducer from './mainReducer';
import thunk from "redux-thunk";
import { saveState,loadState } from '../services/redux.statePersistence';
import throttle from 'lodash.throttle';

const middleware = [thunk];

const persistedState = loadState();

const store = createStore(mainReducer,persistedState,
    composeWithDevTools(
        applyMiddleware(...middleware)
    ));


/**
 * this code is for Persisting Redux State to Local Storage
 * Throttle: invokes a function at most once per every X milliseconds, its used to avoid performance issues
 * The saveState function is called inside the store.subscribe listener so it is called every time the storage state changes. However, it should not be called too often because it uses the expensive JSON.stringify operation.
 */
store.subscribe(throttle (()=>{
    saveState(store.getState());
}),1000);

export default store;