import React, { useEffect, useState } from 'react'
import { PUBLIC_URL } from './../../../Varialbles'
import { Link } from 'react-router-dom'
import Modal from "react-bootstrap/Modal";
import { VFSBrowser } from './../../admin/components/VFSBrowser'
import { useFormik,useFormikContext  } from 'formik';
import { useHistory } from "react-router-dom";
import Noty from 'noty';
import {useSelector, useDispatch} from 'react-redux'
import { persistWork, saveWork } from '../../../redux/Actions/WorkActions';
import { loadAdminDetailedWorks } from '../../../redux/Actions/WorkActions';
import {deleteSection} from './../../../services/work.service'



function AddWork() {
    const history = useHistory();
    const dispatch = useDispatch();
    // console.log("public url :" + process.env.PUBLIC_URL)
    const [isOpen, setIsOpen] = React.useState(false);
    
    const [imgFocus, setImgFocus] = useState(null);
    const [buttonFocus,setButtonFocus] = useState(null)

    // const { submitForm } = useFormikContext();
    const adminWork = useSelector(state => state.WorkReducer.adminWork);
    const adminDetailedWorks = useSelector(state => state.WorkReducer.adminDetailedWorks);
    const [workSections,setWorkSections] = useState({
        GD:false,
        WD:false,
        VP:false,
        SEO:false,
        DM:false
    })

     console.log('adminDWorks : ',adminDetailedWorks)
     console.log(workSections);

    const [thumbnail1, setThumbnail1] = useState(null);
    const [thumbnail2, setThumbnail2] = useState(null);

    useEffect(()=>{

        if(adminWork){
            
            // alert(adminWork.response.data[0].images.cover)
            dispatch(loadAdminDetailedWorks(adminWork.response.data[0].workIdentifier))
            .then(() => {
                console.log('...............loading detailed works')
                setThumbnail1(adminWork.response.data[0].images.thumbnail)
                setThumbnail2(adminWork.response.data[0].images.cover)
            })
            .catch(() => {
                console.log('...............catch error')
            });
            
        }

        if(adminDetailedWorks){
            if(adminDetailedWorks.detailedWorks.data[0].work.sections.length>0){
                let initialeSections = {
                    GD:false,
                    WD:false,
                    VP:false,
                    SEO:false,
                    DM:false
                }
                adminDetailedWorks.detailedWorks.data[0].work.sections.forEach(s => {
                    switch (s.name){
                        case 'GD':
                            initialeSections = {...initialeSections,GD:true}
                            break;
                        case 'WD':
                            initialeSections = {...initialeSections,WD:true}
                            break;
                        case 'VP':
                            initialeSections = {...initialeSections,VP:true}
                            break;
                        case 'SEO':
                            initialeSections = {...initialeSections,SEO:true}
                            break;
                        case 'DM':
                            initialeSections = {...initialeSections,DM:true}
                            break;
                    }
                })
                setWorkSections(initialeSections);
            }
        }
            
    },[adminWork])
    
    
    const getInitialState = ()=>{
        let initialState = {}
        if(adminWork){
            adminWork.response.data.forEach((work)=>{
                switch(work.language){
                    case 'LANG_EN' : 
                        initialState = {...initialState,enProjectName:work.title,enSubtitle:work.subtitle}
                        break;
                    case 'LANG_FR' :
                        initialState = {...initialState,frProjectName:work.title,frSubtitle:work.subtitle}
                        break;
                    case 'LANG_AR' : 
                        initialState = {...initialState,arProjectName:work.title,arSubtitle:work.subtitle}
                }
            })
            return initialState
        }else{
            return {
                enProjectName: '',
                arProjectName: '',
                frProjectName: '',
                enSubtitle: '',
                arSubtitle: '',
                frSubtitle: ''
            }
        }
    }

    const showModal = (img) => {
        setImgFocus(img)
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const insertImg = (url) => {
        switch (imgFocus) {
            case 1:
                setThumbnail1(url);
                formik.validateForm();
                break;
            case 2:
                setThumbnail2(url);
                formik.validateForm();
                break;
        }
    }

    const validate = values => {
        const errors = {};
        if (!values.enProjectName) {
            errors.enProjectName = 'Required';
        } 
        // else if (values.enProjectName.length > 30) {
        //     errors.enProjectName = 'Must be 15 characters or less';
        // }

        if (!values.arProjectName) {
            errors.arProjectName = 'Required';
        } 
        // else if (values.arProjectName.length > 30) {
        //     errors.arProjectName = 'Must be 20 characters or less';
        // }

        if (!values.frProjectName) {
            errors.frProjectName = 'Required';
        }
        //  else if (values.frProjectName.length > 30) {
        //     errors.frProjectName = 'Must be 20 characters or less';
        // }

        if (!values.enSubtitle) {
            errors.enSubtitle = 'Required';
        }
        // else if (values.enSubtitle.length > 30) {
        //     errors.enSubtitle = 'Must be 20 characters or less';
        // }

        if (!values.arSubtitle) {
            errors.arSubtitle = 'Required';
        }
        //  else if (values.arSubtitle.length > 30) {
        //     errors.arSubtitle = 'Must be 20 characters or less';
        // }

        if (!values.frSubtitle) {
            errors.frSubtitle = 'Required';
        } 
        // else if (values.frSubtitle.length > 30) {
        //     errors.frSubtitle = 'Must be 20 characters or less';
        // }

        if (!thumbnail1) {
            errors.thumbnail1 = 'Required';
        }
        // else{
        //     errors.thumbnail1 = '';
        // }

        if (!thumbnail2) {
            errors.thumbnail2 = 'Required';
        }
        // else{
        //     errors.thumbnail2 = '';
        // }


        return errors;
    };

    

    const formik = useFormik({
        initialValues: getInitialState(),
        validate,
        onSubmit: (values,num) => {
            // alert(JSON.stringify(values, null, 2));
            // alert('submitted from '+ buttonFocus)
            
            // alert('submitting the form')
            
            if(!adminWork){
                // alert('admin work is here')
                let addWorkRequest = {
                    englishTitle : values.enProjectName,
                    englishSubtitle : values.enSubtitle,
                    arabicTitle : values.arProjectName,
                    arabicSubtitle : values.arSubtitle,
                    frenshTitle : values.frProjectName,
                    frenshSutitle : values.frSubtitle,
                    tumbnail : thumbnail1,
                    cover : thumbnail2
                }

                // alert(JSON.stringify(addWorkRequest))
                
                dispatch(saveWork(addWorkRequest)).then(() => {
                    new Noty({
                        text:'<code>The work is saved successfully!</code>',
                        type: 'success',
                        layout: 'top',
                        theme:'relax',
                        timeout: 6000,
                    }).show();
                })
            }else{
                let editWorkRequest = {
                    workIdentifier: adminWork.response.data[0].workIdentifier,
                    englishTitle : values.enProjectName,
                    englishSubtitle : values.enSubtitle,
                    arabicTitle : values.arProjectName,
                    arabicSubtitle : values.arSubtitle,
                    frenshTitle : values.frProjectName,
                    frenshSutitle : values.frSubtitle,
                    tumbnail : thumbnail1,
                    cover : thumbnail2
                }
                dispatch(persistWork(editWorkRequest)).then(() => {
                    new Noty({
                        text:'<code>The work is edited successfully!</code>',
                        type: 'success',
                        layout: 'top',
                        theme:'relax',
                        timeout: 6000,
                    }).show();
                })
            }

            switch(buttonFocus){
                case 'GD':
                    history.push("/admin/dashboard/works/add_graphique_design");
                    break
                case 'WD':
                    history.push("/admin/dashboard/works/add_Development_web_and_mobile");
                    break
                case 'VP':
                    history.push("/admin/dashboard/works/add_production_video");
                    break
                case 'SEO' :
                    history.push("/admin/dashboard/works/add-seo");
                    break
                case 'DM' :
                    history.push("/admin/dashboard/works/add_wysywyg");
                    break
                default : 
                    // history.push("/admin/dashboard/works/add_graphique_design");
            }
            
        },
    });

    const addGraphicDesign = ()=>{
        setButtonFocus('GD');
        formik.handleSubmit();
    }

    const addWebDevelopment = ()=>{
        setButtonFocus('WD');
        formik.handleSubmit();
    }

    const addVideoProduction = ()=>{
        setButtonFocus('VP');
        formik.handleSubmit();
    }

    const addSeo = ()=>{
        setButtonFocus('SEO');
        formik.handleSubmit();
    }

    const addDigitalMarketing = ()=>{
        setButtonFocus('DM');
        formik.handleSubmit();
    }

    const saveWorkk = ()=>{
        setButtonFocus('save');
        formik.handleSubmit();
    }

    
    const deleteSectionn = (section)=>{
        if(window.confirm('are you sure you want to delete this section ?')){
            deleteSection(adminWork.response.data[0].workIdentifier,section).then(
                (response) => {
                    setWorkSections({...workSections,[section]:false});
                    dispatch(loadAdminDetailedWorks(adminWork.response.data[0].workIdentifier))
                    .then(() => {
                        new Noty({
                            text:'<code>The section is deleted successfully!</code>',
                            type: 'success',
                            layout: 'top',
                            theme:'relax',
                            timeout: 3000,
                        }).show();                    })
                    .catch(() => {
                    });
                },
                (error) => {
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(_content)
                }
            );
        }
        
    }

    return (
        <>
            <div className="admin-section">
                <div className="add-work-admin">
                    <div className="container-header">
                        <h2>Add Work</h2>
                        <Link to="/admin/dashboard/works/add"><button className="button-add"><i className='bx bx-arrow-back'></i> Back</button></Link>
                    </div>
                    <div className="inputs-wrapper">
                        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
                            <div className="input-work">
                                <div className="project-name">
                                    <p>Project name (en)</p>
                                    <input id="enProjectName"
                                        name="enProjectName"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.enProjectName} />
                                         {formik.errors.enProjectName ? <div className="error">{formik.errors.enProjectName}</div> : null}
                                </div>
                                <div className="subtitle">
                                    <p>Subtitle (en)</p>
                                    <input id="enSubtitle"
                                        name="enSubtitle"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.enSubtitle} />
                                        {formik.errors.enSubtitle ? <div className="error">{formik.errors.enSubtitle}</div> : null}
                                </div>
                            </div>
                            <div className="input-work">
                                <div className="project-name">
                                    <p>Project name (fr)</p>
                                    <input id="frProjectName"
                                        name="frProjectName"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.frProjectName} />
                                        {formik.errors.frProjectName ? <div className="error">{formik.errors.frProjectName}</div> : null}
                                </div>
                                <div className="subtitle">
                                    <p>Subtitle (fr)</p>
                                    <input id="frSubtitle"
                                        name="frSubtitle"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.frSubtitle} />
                                        {formik.errors.frSubtitle ? <div className="error">{formik.errors.frSubtitle}</div> : null}
                                </div>
                            </div>
                            <div className="input-work">
                                <div className="project-name">
                                    <p>Project name (ar)</p>
                                    <input id="arProjectName"
                                        name="arProjectName"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.arProjectName} />
                                        {formik.errors.arProjectName ? <div className="error">{formik.errors.arProjectName}</div> : null}
                                </div>
                                <div className="subtitle">
                                    <p>Subtitle (ar)</p>
                                    <input id="arSubtitle"
                                        name="arSubtitle"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.arSubtitle} />
                                        {formik.errors.arSubtitle ? <div className="error">{formik.errors.arSubtitle}</div> : null}
                                </div>
                            </div>

                            <div className="input-work upload-video">
                                <div className="thumbnail">
                                    <p>thumbnail</p>
                                    <button onClick={() => showModal(1)} type="button"><i className='bx bxs-cloud-upload'></i> Upload</button> <br />
                                    {thumbnail1 && <img src={thumbnail1} className="work-uploaded-img" alt="tumbnail" />}
                                    {formik.errors.thumbnail1 ? <div className="error">{formik.errors.thumbnail1}</div> : null}
                                </div>
                                <div className="cover">
                                    <p>Cover</p>
                                    <button onClick={() => showModal(2)} type="button"><i className='bx bxs-cloud-upload'></i> Upload</button> <br />
                                    {thumbnail2 && <img src={thumbnail2} className="work-uploaded-img" alt="tumbnai2" />}
                                    {formik.errors.thumbnail2 ? <div className="error">{formik.errors.thumbnail2}</div> : null}
                                </div>
                            </div>
                            {/* <button type="submit">Submit</button> */}
                        </form>
                       
                        <div className="services-buttons">
                            <span>Services :</span>
                            {!workSections.GD && <button className="GD" onClick={addGraphicDesign}>+ Graphique Design</button>}
                            {/* <Link to="/admin/dashboard/works/add_graphique_design"></Link> */}
                            {/* <Link to="/admin/dashboard/works/add_Development_web_and_mobile"></Link> */}
                            {!workSections.WD &&  <button onClick={addWebDevelopment} className="WM">+ Dev Web & Mobile</button>}
                            {/* <Link to="/admin/dashboard/works/add_production_video"></Link> */}
                            {!workSections.VP &&  <button className="PD" onClick={addVideoProduction}>+ Production Video</button>}
                            {/* <Link to="/admin/dashboard/works/add-seo"></Link> */}
                            {!workSections.SEO && <button className="WS" onClick={addSeo}>+ SEO </button>}
                            {/* <Link to="/admin/dashboard/works/add_wysywyg"></Link> */}
                            {!workSections.DM && <button className="SEO" onClick={addDigitalMarketing}>+ Digital Marketing</button>}
                        </div>

                        <div className="list-services">
                            {workSections.GD && <div className="service-section">
                                <div className="icon-service icon-dg"><img src={PUBLIC_URL + "/assets/images/Work_Images/de.png"} className="img-section" /></div>
                                <div className="name-maj">
                                    <h4 className="name-title">Graphique&nbsp;design</h4>
                                </div>
                                <div className="icon-maj">
                                    <Link to=""><img src={PUBLIC_URL + "/assets/images/Work_Images/preview.png"} className="img-icon" /></Link>
                                    <Link to="/admin/dashboard/works/add_graphique_design"><img src={PUBLIC_URL + "/assets/images/Work_Images/update.png"} className="img-icon" /></Link>
                                    <button className="delete-button" onClick={()=>deleteSectionn('GD')}><img src={PUBLIC_URL + "/assets/images/Work_Images/delete.png"} className="img-icon" /></button>
                                </div>
                            </div>}

                            {workSections.WD && <div className="service-section">
                                <div className="icon-service icon-dev"><img src={PUBLIC_URL + "/assets/images/Work_Images/wb.png"} className="img-section" /></div>
                                <div className="name-maj">
                                    <h4 className="name-title">Dev&nbsp;web&nbsp;&&nbsp;mobile</h4>
                                </div>
                                <div className="icon-maj">
                                    <Link to=""><img src={PUBLIC_URL + "/assets/images/Work_Images/preview.png"} className="img-icon" /></Link>
                                    <Link to="/admin/dashboard/works/add_Development_web_and_mobile"><img src={PUBLIC_URL + "/assets/images/Work_Images/update.png"} className="img-icon" /></Link>
                                    <button className="delete-button" onClick={()=>deleteSectionn('WD')}><img src={PUBLIC_URL + "/assets/images/Work_Images/delete.png"} className="img-icon" /></button>
                                </div>
                            </div>}

                            {workSections.VP && <div className="service-section">
                                <div className="icon-service icon-pv"><img src={PUBLIC_URL + "/assets/images/Work_Images/pr.png"} className="img-section" /></div>
                                <div className="name-maj">
                                    <h4 className="name-title">Production&nbsp;Video</h4>
                                </div>
                                <div className="icon-maj">
                                    <Link to=""><img src={PUBLIC_URL + "/assets/images/Work_Images/preview.png"} className="img-icon" /></Link>
                                    <Link to="/admin/dashboard/works/add_production_video"><img src={PUBLIC_URL + "/assets/images/Work_Images/update.png"} className="img-icon" /></Link>
                                    <button className="delete-button" onClick={()=>deleteSectionn('VP')}><img src={PUBLIC_URL + "/assets/images/Work_Images/delete.png"} className="img-icon" /></button>
                                </div>
                            </div>}

                            {workSections.DM && <div className="service-section">
                                <div className="icon-service icon-wys"><img src={PUBLIC_URL + "/assets/images/Work_Images/wy.png"} className="img-section" /></div>
                                <div className="name-maj">
                                    <h4 className="name-title">Digital Marketing</h4>
                                </div>
                                <div className="icon-maj">
                                    <Link to=""><img src={PUBLIC_URL + "/assets/images/Work_Images/preview.png"} className="img-icon" /></Link>
                                    <Link to="/admin/dashboard/works/add_wysywyg"><img src={PUBLIC_URL + "/assets/images/Work_Images/update.png"} className="img-icon" /></Link>
                                    <button className="delete-button" onClick={()=>deleteSectionn('DM')}><img src={PUBLIC_URL + "/assets/images/Work_Images/delete.png"} className="img-icon" /></button>
                                </div>
                            </div>}

                            {workSections.SEO && <div className="service-section">
                                <div className="icon-service icon-seo"><img src={PUBLIC_URL + "/assets/images/Work_Images/so.png"} className="img-section" /></div>
                                <div className="name-maj">
                                    <h4 className="name-title">SEO</h4>
                                </div>
                                <div className="icon-maj">
                                    <Link to=""><img src={PUBLIC_URL + "/assets/images/Work_Images/preview.png"} className="img-icon" /></Link>
                                    <Link to="/admin/dashboard/works/add-seo"><img src={PUBLIC_URL + "/assets/images/Work_Images/update.png"} className="img-icon" /></Link>
                                    <button className="delete-button" onClick={()=>deleteSectionn('SEO')}><img src={PUBLIC_URL + "/assets/images/Work_Images/delete.png"} className="img-icon" /></button>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="alignement-button-fin-page">
                        <Link to="/admin/dashboard/works"><button className="button-fin-page"><i className='bx bxs-x-circle'></i> Cancel</button></Link>
                        <button className="button-fin-page" onClick={saveWorkk}><i className='bx bxs-save'></i> Save </button>
                    </div>
                </div>
            </div>

            <Modal
                show={isOpen}
                onHide={hideModal}
                //size="lg"
                dialogClassName="modal-90w"
                centered
                fullscreen={true}
            >
                <Modal.Header>
                    <Modal.Title>FileManager</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <VFSBrowser open={insertImg} close={hideModal}></VFSBrowser>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={hideModal}>Cancel</button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default AddWork
